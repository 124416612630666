import React, { Fragment } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { bake_cookie, delete_cookie, read_cookie } from "sfcookies";
import { OverlayTrigger, Popover } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faCircle, faEye, faFrown,
    faPencilAlt, faStar, faThumbsUp,
    faTimes,
    faTrash, faWrench,
} from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Dropdown from "react-bootstrap/Dropdown";

import { createMuiTheme, Tooltip } from "@material-ui/core";
const TOKEN = read_cookie( "inventory-tkn" );
const BASE_URL = process.env.REACT_APP_BASE_URL;
toast.configure( {
    position: toast.POSITION.BOTTOM_RIGHT
} );

let util = {};


// util.lang = lang.es;

util.ejUnit =  [ "Hour","Day","Month" ,"Year" ];


util.countryFlag = ( country ) => {
    const path = 'images/country-flag/';
    switch ( country.toLowerCase() ){
        case 'mexico': return path + 'mexico.png';
        case 'usa': return path + 'usa.png';
        case 'australia': return path + 'australia.png';
        case 'india': return path + 'india.png';
        case 'japan': return path + 'japan.png';
        case 'germany': return path + 'germany.png';
        case 'canada': return path + 'canada.png';
        default: return path + 'unknown-flag.png';
    }
};

util.keepTextNotTooLong = ( stringValue ) => {
    if ( ! stringValue ) return "";
    if ( stringValue.length > 50 ){
        return (
            <Tooltip title={stringValue}>
                <span>{stringValue.substring( 0,50 )}...</span>
            </Tooltip>
        );
    }else{
        return stringValue;
    }
};

util.productStatus = [
    { value: 0, title: "Para Partes" },
    { value: 1, title: "Nuevo" },
    { value: 2, title: "Usado" },
    { value: 3, title: "Reparado" },
];

util.listOfYears = () => {
    const now = new Date().getUTCFullYear();
    return Array( now - ( now - 50 ) ).fill( '' ).map( ( v, idx ) => now - idx );
};

util.categories = ['Llantas',
    'Motos',
    'Motores',
    'suspenciones',
    'tanque',
    'accesorios',
    'carenado',
    'arrancadores',
    'rines',
    'carburadores',
    'filtros/cartuchos',
    'tapas/cover',
    'radiadores',
    'cableado/electrico',
    'computadoras',
    'ductos',
    'chasis',
    'luces/direcionales',
    'discos/frenos',
    'mangeras',
    'balatas',
    'aceites',
    'tornilleria',
    'pipas/escapes y mas',
    'otros',
];

util.user = read_cookie( "inventory-tkn" ).length > 0 ? jwt_decode( read_cookie( "inventory-tkn" ) ) : {};

util.baseUrl = () => {
    return BASE_URL;
};

util.optionsCursorTrueWithMargin = {
    followCursor: true,
    shiftX: -200,
    shiftY: -150
};

util.BASE_URL = BASE_URL;

util.userRole = ( role ) => {
    switch ( role ) {
        case 1: return "Administrador";
        case 2: return "Empleado";
        case 3: return "Mecánico";
        case 4: return "Proveedor";
    }
};

util.userStatus = ( status ) => {
    switch ( status ) {
        case 0: return "Inactivo";
        case 1: return "Activo";
    }
};
util.defaultTableThemeSlim = createMuiTheme( {
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#2196F3',
        }
    },
    overrides: {

        MuiTableCell: {
            root: {
                // padding: 5,
                fontSize: 13,
                backgroundColor: "transparent"
            },
            head: {
                // textAlign: "center!important",
                textDecoration: "none",
                lineHeight: 1,
                backgroundColor:"#0d6efd!important",
                color: "white",
            },
            body: {
                fontSize: "13px!important"
            }
        }
    }
} );

util.productStatusIcons = ( status, showTitle = true ) => {

    const toolTipsMessages = {
        new: { status: 0, color: "tomato", tooltip: "", icon: faFrown, title: "Para Partes" },
        active:{ status: 1, color: "green", tooltip: "", icon: faStar, title: "Nuevo" },
        hold:{ status: 2, color: "#d4cf05", tooltip: "", icon: faThumbsUp, title: "Usado" },
        closed:{ status: 3, color: "gray", tooltip: "", icon: faWrench, title: "Reparado" },
    };

    const IconStatus = ( { data } ) => {
        const { tooltip, color, icon, title } = data;

        if ( tooltip !== "" ){
            return (
                <Tooltip title={tooltip}>
                    <span>
                        <FontAwesomeIcon
                            color={color}
                            className="mr-2"
                            icon={icon}/>
                        { showTitle ? title: ""}
                    </span>
                </Tooltip>
            );
        }else{
            return (
                <div className="d-flex align-items-center align-content-center justify-content-around">
                    <FontAwesomeIcon
                        color={color}
                        icon={icon}/>
                    { showTitle ? title: ""}
                </div>
            );
        }


    };

    switch ( parseInt( status ) ) {
        case 0: return <IconStatus data={toolTipsMessages.new} />;
        case 1: return <IconStatus data={toolTipsMessages.active} />;
        case 2: return <IconStatus data={toolTipsMessages.hold} />;
        case 3: return <IconStatus data={toolTipsMessages.closed} />;
    }
};

util.getTodayDateDbFormat = new Date().toISOString().split( "." )[0].split( "T" )[0];

util.getTodayTimeStampDbFormat = new Date().toISOString().split( "." )[0].split( "T" ).join( " " );

util.handleError = ( msg ) => {
    if ( process.env["ENVIROMENT"] !== "Production" ) console.log( msg );
    if ( msg.toString().indexOf( "Unauthorized" ) !== -1 ) {
        let e = document.body;
        e.parentNode.removeChild( e );
        setTimeout( () => {
            alert( "Su sesión a expirado. Inicie sesión nuevamente." );
            delete_cookie( "inventory-tkn" );
            window.history.replaceState( "", "", "/" );
            window.location.reload();
        }, 1000 );
    } else if ( msg.toString().indexOf( "_UNIQUE" ) !== -1 ) {
        util.toast().error( "Email must be unique" );
    } else if ( msg.toString().indexOf( "notEmpty" ) !== -1 ) {
        util.toast().error( msg.toString() );
        // switch ( msg ) {
        //     case 'Error: Validation notEmpty on qty failed':
        //
        //         break;
        //     default:
        //         let emsg = msg.split( " " );
        //         util.toast().error( emsg[3].toUpperCase()[0] + emsg[3].substr( 1 ) + " cannot be empty" );
        // }
        
    } else if ( msg.toString().indexOf( "isEmail" ) !== -1 ) {
        util.toast().error( "Email is not valid" );
    } else if ( msg.message !== undefined ) {
        util.toast().error( msg.message );
    } else {
        util.toast().error( msg );
    }
};

util.url = BASE_URL;

util.noValues = [undefined, null, "null", "", "0", 0];



util.axiox = () => {
    return axios.create( {
        baseURL: BASE_URL,
        timeout: 60000,
        headers: { Authorization: `${TOKEN}` },
    } );
};

util.axios = axios.create( {
    baseURL: BASE_URL,
    timeout: 30000,
    headers: { Authorization: `${TOKEN}` },
} );

util.toast = () => {
    return toast;
};

util.humanDateTime = ( date ) => {
    if ( date === null ) return date;
    if ( date === "" ) return "-";
    let d = date.split( "." )[0];
    d = d.split( " " );
    return util.humanDate( d[0] ) + " - " + d[1];
};

util.humanDate = ( date, str = true, longStr = false ) => {
    if ( date === null ) return date;
    if ( date === undefined ) return date;
    if ( date === "" ) return "-";
    let d = date.split( " " )[0].split( "-" );
    let month = d[1];
    if ( str ){
    // Want name of the month instead of the number
        switch ( d[1] ) {
            case '01': month = longStr ? "January" : "JAN";
                break;
            case '02': month = longStr ? "February" : "FEB";
                break;
            case '03': month = longStr ? "March" : "MAR";
                break;
            case '04': month = longStr ? "April" : "APR";
                break;
            case '05': month = longStr ? "May" : "MAY";
                break;
            case '06': month = longStr ? "June" : "JUN";
                break;
            case '07': month = longStr ? "July" : "JUL";
                break;
            case '08': month = longStr ? "August" : "AUG";
                break;
            case '09': month = longStr ? "September" : "SEP";
                break;
            case '10': month = longStr ? "October" : "OCT";
                break;
            case '11': month = longStr ? "November" : "NOV";
                break;
            case '12': month = longStr ? "December" : "DEC";
                break;
            default:
                month = d[1];
                break;
        }
    }
  
    // return <Badge variant="info">{finalDate}</Badge>
    return `${d[2]}/${month}/${d[0]}`;
};


util.currencyFormat = ( value, country ) => {
    if ( !util.noValues.includes( value ) ) {
        let a = parseFloat( value ).toLocaleString( 'en-US', { style: 'currency', currency: 'USD' } );
        a = a.split( "$" );

        return util.symbolDependingCurrencySelected( country ) + a[1];
    } else {
        return util.symbolDependingCurrencySelected( country ) + `0.00`;
    }
};


util.dateLong = ( date ) => {
    const d = new Date( date ).toString().split( "GMT" )[0];
    return d.slice( 0, d.length - 1 );
};


util.formatPhone = ( phone ) => {
    if ( phone === null ) return "";
    let length = phone.length;
    if ( length > 10 ) {
        switch ( length ) {
            case 11:
                return "+" + phone.slice( 0, 1 ) + " (" + phone.slice( 1, 4 ) + ") " + phone.slice( 4, 7 ) + " " + phone.slice( 7, 11 );
            case 12:
                return "+" + phone.slice( 0, 2 ) + " (" + phone.slice( 2, 5 ) + ") " + phone.slice( 5, 8 ) + " " + phone.slice( 8, 12 );
            case 13:
                return "+" + phone.slice( 0, 2 ) + " (" + phone.slice( 2, 5 ) + ") " + phone.slice( 5, 8 ) + " " + phone.slice( 8, 12 );
            default:
                break;
        }
    } else {
        return phone;
    }
	
};

util.urlHandlerCandidates = ( query ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "name";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    return `${util.url}/candidates/${query.page}/${query.pageSize}?orderby=${orderby}&direction=${direction}&search=${search}`;
	
};

util.urlHandlerJobPositions = ( query ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    return `${util.url}/requirements/list_of_requirements/${query.page}/${query.pageSize}?page=${query.page}&rows=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}`;
	
};

util.urlHandlerJobPositions2 = ( query ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    return `${util.url}/jobs/list?page=${query.page}&rows=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}`;
};

util.urlJobPositionsTalent = ( query ) => {
	
    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let search = ( query.search === "" ) ? "" : query.search;
	
	
    let q = `page=${query.page}
  &limit=${query.pageSize ? query.pageSize : 15}
&job_position=${search}
&orderby=${orderby}`;
	
	
    return `${util.url}/jobs/position?${q}`;
};

util.urlHandlerUsers = ( query, role, opc = false ) => {
    let orderby = ( query.orderBy ) ? query.orderBy.field : "id";
    let direction = ( query.orderDirection === "" ) ? "asc" : query.orderDirection;
    let search = ( query.search === "" ) ? "" : query.search;
    let options = ( opc ) ? `&opt=${opc}` : "";
    return `${util.url}/users/list?page=${query.page}&limit=${query.pageSize}&orderby=${orderby}&direction=${direction}&search=${search}&role=${role}${options}`;
	
};

util.roleToString = ( role ) => {
    let roleInt = parseInt( role );
    switch ( roleInt ) {
        case 1:
            return "Recruiter";
        case 2:
            return "Talent";
        case 3:
            return "Client";
        case 4:
            return "Partner";
        case 7:
            return "Employee";
        case 10:
            return "Admin";
        default:
            break;
    }
};

util.SecondaryRoleList = ( data ) => {
    let list = [];
    list.push( data.permissions.recruiter === 1 ? "Recruiter"  : "" );
    list.push( data.permissions.recruiter_manager === 1 ? "Recruiter Manager"  : "" );
    list.push( data.permissions.talent === 1 ? "Talent"  : "" );
    list.push( data.permissions.client === 1 ? "Client"  : "" );
    list.push( data.permissions.partner === 1 ? "Partner"  : "" );
    list.push( data.permissions.employee === 1 ? "Employee"  : "" );
    list.push( data.permissions.employee_manager === 1 ? "Employee manager"  : "" );
    return list.filter ( e =>  e );
    // return result.join( ", " );
};

util.stopProcessMotives = ( value ) => {
    let valueInt = parseInt( value );
    switch ( valueInt ) {
        case 1:
            return "Candidate No Longer Available";
        case 2:
            return "Candidate Not Cleared a client Round";
        case 3:
            return "Candidate Not Cleared an internal Round";
        case 4:
            return "Candidate BGC not cleared";
        case 5:
            return "Candidate no longer available";
        default:
            break;
    }
};

util.handleColumnsByRole = ( field ) => {
    let admin = [
        'editOptions',
        'buy_from',
        'buy_rate',
        'buy_to',
        'candidates_added',
        'certification',
        'city',
        'client_id',
        'client_requirement_id',
        'clientEmail',
        'company',
        'country',
        'createdAt',
        'deal_type',
        'duration',
        'education',
        'employment_type',
        'endClientName',
        'id',
        'job_description',
        'job_title',
        'lead_recruiter',
        'level',
        'margin',
        'owner_office',
        'partner_id',
        'personName',
        'primary_skill',
        'priority',
        'reporting_manager',
        'reporting_manager_address',
        'reporting_manager_phone',
        'restricted_to',
        'role',
        'sales_lead',
        'scope',
        'secondary_recruiter',
        'secondary_skill',
        'sell_rate',
        'state',
        'status',
        'zipCode',
    ];
    const talent = [
        'city',
        'country',
        'createdAt',
        'id',
        'job_description',
        'job_title',
        'restricted_to',
        'state',
    ];
    const partnerAndClient = [
        'id',
        'personName',
        'endClientName',
        'job_title',
        'sell_rate',
        'status',
        'country',
        'company',
        'city',
        'state',
        'createdAt',
    ];
    let result = true;
    switch ( util.user.role ) {
        case 1:
            admin.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        case 2:
            talent.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        case 3:
            partnerAndClient.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        case 4:
            partnerAndClient.forEach( ( col ) => {
                if ( col === field ) {
                    result = false;
                }
            } );
            break;
        default:
            result = false;
            break;
    }
	
    return result;
	
};


// util.themeEvaluation = createMuiTheme( {
//     palette: {
//         primary: {
//             // Purple and green play nicely together.
//             main: "#007bff",
//         },
//         secondary: {
//             // This is green.A700 as hex.
//             main: '#ff0000',
//         },
//         error: {
//             // This is green.A700 as hex.
//             main: '#ff0000',
//         }
//     },
// } );
//
// util.defaultTableTheme = createMuiTheme( {
//     palette: {
//         primary: {
//             main: '#2196F3',
//         },
//         secondary: {
//             main: '#2196F3',
//         }
//     },
//     overrides: {
//
//         MuiTableCell: {
//             root: {
//                 padding: 3,
//                 fontSize: 13,
//                 backgroundColor: "transparent"
//             },
//             head: {
//                 // textAlign:"center!important",
//                 textDecoration: "none",
//                 lineHeight: 1,
//                 backgroundColor: "dodgerblue!important",
//                 color: "white"
//             },
//             body: {
//                 fontSize: "12px!important"
//             }
//         }
//     }
// } );
//
// util.defaultTableThemeSlim = createMuiTheme( {
//     // palette: {
//     //   primary: {
//     //     main: '#2196F3',
//     //   },
//     //   secondary: {
//     //     main: '#2196F3',
//     //   }
//     // },
//     overrides: {
//
//         MuiTableCell: {
//             root: {
//                 padding: 2,
//                 fontSize: 12,
//                 backgroundColor: "transparent"
//             },
//             head: {
//                 textAlign: "center!important",
//                 textDecoration: "none",
//                 lineHeight: 1,
//                 // backgroundColor:"dodgerblue!important",
//                 color: "black",
//             },
//             body: {
//                 fontSize: "11px!important"
//             }
//         }
//     }
// } );

util.statusButton = ( status ) => {
    switch ( status ) {
        case 1:
            return "Hold";
        case 2:
            return "Active";
        case 3:
            return "Closed by Client";
        case 4:
            return "Filled";
        default:
            return "Unknown";
    }
};

util.statusJP = ( status ) => {
    switch ( status ) {
        case 1:
            return "Active";
        case 2:
            return "Hold";
        case 3:
            return "Closed by Client";
        case 4:
            return "Filled";
        default:
            return "Unknown";
    }
};

util.statusDictionary = ( status ) => {
    /**
	 * status
	 * 1 = open
	 * 2 = Hold
	 * 3 = cancelled by client
	 * */
    switch ( status ) {
        case 1:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
			                                                       className={"text-success"}/>} Active</span>;
        case 2:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
			                                                       className={"text-warning"}/>} Hold</span>;
        case 3:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
			                                                       className={"text-danger"}/>} Closed</span>;
        case 4:
            return <span className="status_font">{<FontAwesomeIcon icon={faCircle}
			                                                       className={"text-info"}/>} Filled</span>;
        default:
            return status;
    }
};



util.validateEmail = ( email ) => {
    // eslint-disable-next-line
	const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test( String( email ).toLowerCase() );
};

util.employment_type = [
    { employment_type: "Fulltime" },
    { employment_type: "C2C" },
    { employment_type: "Contractor" }
];

util.BoxSimple = ( props ) => {
    const { name, email, company, phone1, phone2 } = props.data;
    const option = ( props.type === 1 ) ? 1 : 2;
    return <Fragment>
        <div className="textSmall">
            <div>{name ? name : ""} {props.disabled ? "" :
                <span className="btn btn-default" onClick={() => props.editInfo( option )}><FontAwesomeIcon
                    icon={faPencilAlt}/></span>}</div>
            {email ? <div className="text-danger"
			              style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{email}</div> : ""}
            {company ? <div>{company}</div> : ""}
            {phone1 ? <div>{phone1}</div> : ""}
            {phone2 ? <div>{phone2}</div> : ""}
        </div>
    </Fragment>;
};

util.zeroPad = ( num, places ) => {
    return String( num ).padStart( places, "0" );
};


util.suspenseDotPopOver = ( string, search ) => {
    if ( string !== "" ) {
        if ( string?.length > 20 ) {
            const skills = string.split( "," );
            const popover = (
                <Popover id="popover-basic">
                    <Popover.Title className="titlePopoverH3" as="h3">Skills</Popover.Title>
                    <Popover.Content>
                        <div className={skills.length > 15 ? "popOverScrollable2" : "popOverScrollable1"}>
                            <ul>
                                {skills.map( ( s, i ) => {
                                    if ( !util.noValues.includes( search ) ) {
                                        if ( s.toLowerCase().includes( search.toLowerCase() ) ) {
                                            return <li key={i}><b><u>{s}</u></b></li>;
                                        } else {
                                            return <li key={i}>{s}</li>;
                                        }
                                    } else {
                                        return <li key={i}>{s}</li>;
                                    }
                                } )}
                            </ul>
                        </div>
                    </Popover.Content>
                </Popover>
            );
			
            return (
                <OverlayTrigger
                    rootClose={true}
                    rootCloseEvent='click'
                    trigger={['hover', 'focus']}
                    placement="right"
                    overlay={popover}
                >
                    <div>
                        {string.substring( 0, 20 ) + "..."}
                    </div>
                </OverlayTrigger>
            );
        } else {
            return string;
        }
		
    } else {
        return "";
    }
};

function createMarkup( job_description ) {
    return { __html: job_description };
}

util.suspenseDotPopOverJobDescription = ( row ) =>{
  
    if ( row !== "" ){
        if ( row.job_description?.length > 20 ){
            const popover = (
                <Popover id="popover-basic">
                    <Popover.Title className="titlePopoverH3" as="h3">Job Description</Popover.Title>
                    <Popover.Content>
                        <div className="popOverScrollable1">
                            <ul>
                                <li>
                                    {
                                        <div dangerouslySetInnerHTML={createMarkup( row.job_description )}></div>
                                    }
                                </li>
                            </ul>
                        </div>
                    </Popover.Content>
                </Popover>
            );
      
            return(
                <OverlayTrigger
                    rootClose={true}
                    rootCloseEvent='click'
                    trigger='click'
                    placement="right"
                    overlay={popover}
                >
	          <FontAwesomeIcon icon={faEye} className="iconGray isCursor" />

                </OverlayTrigger>
            );
        }else{
            return "";
        }
    
    }else{
        return "";
    }
};


util.suspenseDotPopOverLastJob = ( string, search ) =>{
    if ( string !== "" ){
        if ( string?.length > 20 ){
            const skills = string.split( "," );
            const popover = (
                <Popover id="popover-basic">
                    <Popover.Title className="titlePopoverH3" as="h3">Last Job Positions</Popover.Title>
                    <Popover.Content>
                        <div className={skills.length >15 ? "popOverScrollable2":"popOverScrollable1"}>
                            <ol>
                                {skills.map( ( s, i ) =>{
                                    if ( !util.noValues.includes( search ) ){
                                        if ( s.trim() === "" ) return null;
                                        if ( s.toLowerCase().includes( search.toLowerCase() ) ){
                                            return <li key={i}><b><u>{s}</u></b></li>;
                                        }else{
                                            return <li key={i}>{s}</li>;
                                        }
                                    }else{
                                        return <li key={i}>{s}</li>;
                                    }
                                } )}
                            </ol>
                        </div>
                    </Popover.Content>
                </Popover>
            );
      
            return(
                <OverlayTrigger
                    rootClose={true}
                    rootCloseEvent='click'
                    trigger={['hover', 'focus']}
                    placement="right"
                    overlay={popover}
                >
                    <div>
                        {string.substring( 0, 20 ) + "..."}
                    </div>
                </OverlayTrigger>
            );
        }else{
            return string;
        }
    
    }else{
        return "";
    }
};



util.LOADING_SCREEN = ( loading ) => {
    return <Backdrop open={loading ? loading : false} style={{ zIndex: 50000 }} addEndListener={null}>
        <div className="text-center"><CircularProgress/></div>
    </Backdrop>;
};

util.whatsapp = ( tel, msg ) => {
    // https://web.whatsapp.com/send?phone=&text=
    return `https://web.whatsapp.com/send?phone=${tel}&text=${encodeURI( msg )}`;
};

util.randomStringPassword = () => {
    return Math.random()
        .toString( 36 )
        .replace( /[^a-z]+/g, "" )
        .substr( 0, 12 );
};


util.symbolDependingCurrencySelected = ( country ) => {
	
    const currencySymbols = [
        { country: "USA", symbol: "$" },
        { country: "Mexico", symbol: "MX$" },
        { country: "Canada", symbol: "CA$" },
        { country: "Australia", symbol: "AU$" },
        { country: "India", symbol: "₹" },
        { country: "Japan", symbol: "¥" }
    ];
	
    for ( let x = 0; x < currencySymbols.length; x++ ) {
        const c = currencySymbols[x];
        if ( c.country === country ) {
            return c.symbol;
        }
    }
    return `$`;
};

util.startLoading = ( setLoading ) => setLoading( true );

util.stopLoading = ( setLoading ) => setLoading( false );

util.working_status = [
    { working_status: "US Citizen" },
    { working_status: "Mexican" },
    { working_status: "Indian" },
    { working_status: "Australian" },
    { working_status: "Permanent Legal Resident" },
    { working_status: "Green Card" },
    { working_status: "Green Card - EAD" },
    { working_status: "H1B" },
    { working_status: "H4 - EAD" },
    { working_status: "Optional Practical Training (OPT)" },
    { working_status: "Curricular Practical Training (CPT)" },
    { working_status: "Treaty NAFTA (TN)" },
    { working_status: "Other" }
];

util.stringToCap = ( name ) => {
    if ( name === "" ) return "";
    let n = name.toString().toLowerCase().split( " " );
    for ( let x = 0; x < n.length; x++ ) {
        if ( n[x][0] !== undefined ) {
            n[x] = n[x][0].toUpperCase() + n[x].slice( 1 );
        }
		
    }
    return n.join( " " );
};

util.handleValueByRecruiterCountry = ( value, opc ) => {
    switch ( opc ) {
        case'country':
            if ( value === "" ) {
                return util.user.country;
            } else {
                return value;
            }
        case'currency':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'USA':
                        return "USD";
                    case 'Australia':
                        return "AUD";
                    case 'Mexico':
                        return "MXN";
                    case 'India':
                        return "INR";
                    case 'Canada':
                        return "CAD";
                    default:
                        return "USD";
                }
            } else {
                return value;
            }
        case'salary_mode':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'USA':
                        return "Year";
                    case 'Australia':
                        return "Day";
                    case 'Mexico':
                        return "Monthly";
                    case 'India':
                        return "Day";
                    case 'Canada':
                        return "Year";
                    default:
                        return "Year";
                }
            } else {
                return value;
            }
        case'working_status':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'Australia':
                        return "Permanent Legal Resident";
                    default:
                        return "";
                }
            } else {
                return value;
            }
        case'phone_mobile':
        case'phone_home':
        case'phone_other':
            if ( value === "" ) {
                switch ( util.user.country ) {
                    case 'Australia':
                        return "61" + value;
                    case 'India':
                        return "91" + value;
                    case 'Mexico':
                        return "52" + value;
                    default:
                        return value;
                }
            } else {
                return value;
            }
        default:
    }
	
};

util.logOut = () => {
	
    const urlParams = new URLSearchParams( window.location.search );
    const jobId = urlParams.get( 'job' );
	
    if ( jobId !== null ) {
        window.history.replaceState( "", "", `/careers?search=&job=${jobId}` );
    } else {
        window.history.replaceState( "", "", "/" );
    }
    delete_cookie( "inventory-tkn" );
    // Every time the talent log in, it will be redirected to the main page
    setTimeout( () => {
        window.location.reload();
    }, 1500 );
};

util.changeRole = async ( selectedRole ) => {
    try {
        let role = 2;
        switch ( selectedRole ) {
            case "recruiter":
                role = 1;
                break;
            case "talent":
                role = 2;
                break;
            case "client":
                role = 3;
                break;
            case "partner":
                role = 4;
                break;
            case "evaluator":
                role = 5;
                break;
            case "recruiter_manager":
                role = 6;
                break;
            case "employee":
                role = 7;
                break;
            case "employee_manager":
                role = 8;
                break;
            default:
                break;
        }
        let u = util.user;
        u.role = role;
		
        const request = await axios.put( `${util.url}/auth/change_user_role`, { user: u } );
        const { error, msg, accessToken } = request.data;
        if ( error ) throw msg;
        bake_cookie( "inventory-tkn", accessToken );
        window.history.replaceState( "", "", "/" );
        window.location.reload();
    } catch ( e ) {
        util.handleError( e );
    }
};

util.AvailableRole = ( { selectedRole, role } ) => {
    if ( util.user.permissions[selectedRole] === 1 ) {
        return (
            <Dropdown.Item>
                <div onClick={() => util.changeRole( selectedRole, util.user.permissions.recruiter )}>{role}</div>
            </Dropdown.Item>
        );
    } else {
        return "";
    }
};

util.uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function ( c ) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
        return v.toString( 16 );
    } );
};

util.fieldToHumanWord = ( field ) => {
    switch ( field ) {
        case 'id':
            return 'Id';
        case 'createdAt':
            return 'Created At';
        case 'created_by':
            return 'Created By';
        case 'updated_by':
            return 'updated By';
        case 'company':
            return 'Company';
        case 'client_company':
            return 'Client Company';
        case 'job_title':
            return 'Job Title';
        case 'sell_rate':
            return 'Sell Rate';
        case 'status':
            return 'Status';
        case 'candidates_added':
            return 'Candidates Added';
        case 'country':
            return 'Country';
        case 'reporting_manager':
            return 'Reporting Manager';
        case 'sales_lead':
            return 'Sales Lead';
        case 'lead_recruiter':
            return 'Lead Recruiter';
        case 'secondary_recruiter':
            return 'Secondary Recruiter';
        case 'restricted_to':
            return 'Restricted To';
        default:
            return field;
    }
};

util.highestEducation = [
    { highestEducation: "High School" },
    { highestEducation: "Diploma" },
    { highestEducation: "Graduate" },
    { highestEducation: "Masters (Post Graduate)" },
    { highestEducation: "Doctoral (Ph.D)" },
];

util.isProfilePicAvailableURL = ( d ) => {
    // let picSize = opc ? size : 35;
    if ( !util.noValues.includes( d.profile_picture ) ) {
        return BASE_URL + "/candidate/" + d.id + "/" + d.profile_picture;
        // return <img width={`${picSize}`} height={`${picSize}`} className="rounded-circle imageZoomed" alt={d.name.substring(0,5)} src={url} />;
    } else {
        return "/images/avatar.svg";
    }
};

// Check if user is trying to visit a job in fullScreen
util.isFullScreenJob = () => {
    if ( window.document.location.search !== "" ) {
        const urlParams = new URLSearchParams( window.location.search );
        const fs = urlParams.get( 'fs' );
        if ( fs ) {
            return { sidebar: '0 d-none', main: 12 };
        }
        {
            return { sidebar: 4, main: 8 };
        }
    }
};

util.SelectSlim = ( { name, value, onChange, options } ) => {
    return (
        <select
            name={name}
            onChange={onChange}
            value={value}
            className="form-control"
        >
            {options.map( ( o, i ) => {
                return <option value={o} key={i}>{o}</option>;
            } )}
        </select>
    );
};

util.PopOverConfirmation = ( { callBackOk } ) => {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h5">Esta seguro?</Popover.Title>
            <Popover.Content>
                <div className="d-flex justify-content-around">
                    <span onClick={() => document.body.click()} className="isPointer text-danger">
                        <FontAwesomeIcon icon={faTimes}/>
                    </span>
                    <span onClick={() => {
                        document.body.click();
                        callBackOk();
                    }} className="isPointer text-success">
                        <FontAwesomeIcon icon={faCheck}/>
                    </span>
                </div>
            </Popover.Content>
        </Popover>
    );
	
    return (
        <OverlayTrigger
            rootClose
            rootCloseEvent="click"
            trigger={['click']} placement="right" overlay={popover}>
            <Tooltip title="Eliminar">
                <span className="pl-2">
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="isPointer"
                        color="#b7b4b4"
                    />
                </span>
            </Tooltip>
        </OverlayTrigger>
    );
};

util.InputSlim = ( { name, value, type, onChange, placeholder, disabled = false } ) => {
    return (
        <input
            disabled={disabled}
            type={type ? type : "text"}
            className="form-control"
            onChange={onChange}
            name={name}
            value={value ? value : ""}
            placeholder={placeholder}
        />
    );
};

util.fixDate = date => {
    if ( util.noValues.includes( date ) ) return "";
    let d = util.humanDate( date, true ).split( "/" );
    return `${d[0]}/${d[2]}`;
};


util.typeSkill = ( type ) => {
    const PRIMARY = "Primary";
    const SECONDARY = "Secondary";
    const SOFT = "Soft";
	
    switch ( type ) {
        case PRIMARY:
            return 1;
        case SECONDARY:
            return 2;
        case SOFT:
            return 3;
    }
};
export default util;
