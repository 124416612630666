import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import util from "../../../../utils/miniUtils";
import { ADD_INVENTORY, REMOVE_INVENTORY } from "../../../../utils/constants";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "react-bootstrap-sweetalert";


export default function ModifyInventory( { show, handleClose, products = [], reloadData, inventoryAction = ADD_INVENTORY } ) {

    const [productsLocal, setProductsLocal] = useState( products );
    const [errors, setErrors] = useState( {
        showErrorAlert: false
    } );
    const [loading, setLoading] = useState( false );
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );

    useEffect( () => {
        let a = JSON.parse( JSON.stringify( products ) );
        for ( let i = 0; i < a.length; i++ ) {
            a[i].qty = "";
            a[i].date = util.getTodayDateDbFormat;
        }
        setProductsLocal( a );
    }, [products, inventoryAction] );

    const onChangeValues = ( e, index ) => {
        let { name, value } = e.target;
        // if ( value === "" ) value = 1;
        let t = [...productsLocal];
        t[index][name] = value;
        setProductsLocal( Object.assign( [], t ) );
    };

    const saveChangesOnInventory = async () => {
        try {
            startLoading();
            let response;
            let isValid = true;
            if ( inventoryAction === ADD_INVENTORY ){
                response = await util.axios.post( `inventory/data/add/`, { addInventory: productsLocal } );
            }else{
                // Validate user can remove more products that he has
                for ( let i = 0; i < products.length; i++ ) {
                    if ( parseInt( productsLocal[i].qty ) > products[i].qty ){
                        setErrors( {
                            ...errors,
                            showErrorAlert: true,
                            stored: products[i].qty,
                            newStored: productsLocal[i].qty,
                            prod: productsLocal[i]
                        } );
                        isValid = false;
                        break;
                    }
                }
                if ( !isValid ) {
                    stopLoading();
                    return false;
                }
                response = await util.axios.post( `inventory/data/remove/`, { removeInventory: productsLocal } );
            }

            if ( inventoryAction === REMOVE_INVENTORY && !isValid ) return;

            const { error, msg } = response["data"];
            if ( error ) throw new Error( msg );
            stopLoading();
            await reloadData();
            util.toast().success( msg );
            handleClose();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    return (
        <Fragment>
            <Modal size={"lg"} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{inventoryAction === ADD_INVENTORY ? "Agregar": "Remover de" } Inventario de Productos</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        productsLocal.length > 0 ?
                            <Fragment>
                                <div className="table-responsive">
                                    <table className="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Categoría</th>
                                                <th>Articulo</th>
                                                <th>Cant. Actual</th>
                                                <th>Cantidad</th>
                                                <th>Fecha</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                productsLocal.map( ( p,i ) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <tr>
                                                                <td className="align-middle">{util.zeroPad( p.id , 5 )}</td>
                                                                <td className="align-middle">{p.article}</td>
                                                                <td className="align-middle">{p.categoryName}</td>
                                                                <td className="text-center align-middle">{products[i].qty}</td>
                                                                <td width={100}>
                                                                    <input
                                                                        onChange={( e )=> onChangeValues( e, i )}
                                                                        type="number"
                                                                        name="qty"
                                                                        value={p.qty ? p.qty: ""}
                                                                        className="form-control"
                                                                    />
                                                                </td>
                                                                <td width={100}>
                                                                    <input
                                                                        onChange={( e )=> onChangeValues( e, i )}
                                                                        type="datetime-local"
                                                                        name="date"
                                                                        value={p.date ? p.date: util.getTodayDateDbFormat}
                                                                        className="form-control"
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    );
                                                } )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Fragment>
                            :
                            null
                    }

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm" onClick={handleClose}>
						Cancelar
                    </button>
                    {
                        inventoryAction === ADD_INVENTORY ?
                            <button className="btn btn-sm btn-success" onClick={saveChangesOnInventory}>
                                <FontAwesomeIcon icon={faPlusCircle}/>
                                <span className="p-2">
									Agregar a Inventario
                                </span>
                            </button>
                            :
                            <button className="btn btn-sm btn-warning" onClick={saveChangesOnInventory}>
                                <FontAwesomeIcon icon={faMinusCircle}/>
                                <span className="p-2">
									Remover de Inventario
                                </span>
                            </button>

                    }

                </Modal.Footer>
            </Modal>

            {
                errors.showErrorAlert ?
                    <SweetAlert
                        show={errors.showErrorAlert}
                        warning
                        confirmBtnText="Ok"
                        confirmBtnBsStyle="warning"
                        title="No puedes remover más productos de los existentes"
                        onConfirm={() => {
                            setErrors( { ...errors, showErrorAlert: false } );
                        }}
                        onCancel={() => setErrors( { ...errors, showErrorAlert: false } )}
                    >
                        <div className="">
                            <p>Producto: {errors.prod.article}</p>
                            <p>En Existencia: {errors.stored}</p>
                            <p>De Baja: {errors.newStored}</p>
                        </div>
                    </SweetAlert>
                    :
                    null
            }

            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}