import { Store } from "pullstate";

export const ProductStore = new Store( {
    product:{
        article: "",
        description: "",
        status: 1,
        qty: "",
        price: "",
        category_id: 0,
        brand_id: 0,
        model_id: 0,
        internal_id: "",
        year: "",
        year_end: "",
        size_id: 0,
    },
    imagesLocal:[],
    imagesServer:[],
    selectedFiles:[],
    categories:[],
    brands:[],
    models:[],
    sizes:[],
    showAddCategory:false,
    showAddBrand:false,
    showAddModel:false,
    showAddSize:false,
    tempValue:"",
    newBrandName:"",
    newModelName:"",
    newSizeName:"",

} );