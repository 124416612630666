
export const ADD_INVENTORY      = 1;
export const REMOVE_INVENTORY   = 2;
export const ADD_PRODUCT        = 1;
export const EDIT_PRODUCT       = 2;
export const KEEP_ADDING        = 3;
export const ADD_USER           = 1;
export const ADMIN_EMAIL        = "admin@admin.com";
export const EDIT_USER          = 2;
export const DETAIL_PRODUCT     = 3;
export const CATEGORIES         = "category";
export const BRANDS             = "brand";
export const MODELS             = "model";
export const SIZES              = "size";
export const ADMIN_USER         = 1;
export const EMPLOYEE_USER      = 2;
export const MECHANIC_USER      = 3;
export const PROVIDER_USER      = 4;