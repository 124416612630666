import React, { Fragment, useEffect, useState } from "react";
import InfoBox from "./InfoBox";
import { faBox, faUsers, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import util from "../../utils/miniUtils";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

export default function Dashboard() {

    const [data, setData] = useState( null );
    
    useEffect( () => {
        getDashboardData().then( null );
    },[] );

    async function getDashboardData() {
        try {
            const request = await util.axios.get( `dashboard/totals` );
            let { error, msg, data } = request.data;
            if ( error ) throw msg;
            setData( data );
        }catch ( e ) {
            util.handleError( e );
        }
    }

    if ( data === null ){
        return util.LOADING_SCREEN( true );
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="p-2">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4"><InfoBox icon={faBox} total={data.products} title="Productos" link="productos" /></div>
                    <div className="col-md-4"><InfoBox icon={faUsers} total={data.users} title="Usuarios" link="usuarios" /></div>
                    <div className="col-md-4"><InfoBox icon={faWarehouse} total={data.categories} title="Categorías" /></div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-4">
                        <TableOfResults data={data.productsLast10} title="Últimos 10 productos modificados" />
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-md-6">
                        <TableOfResults data={data.latestAdded} title="Últimas 10 Altas" />
                    </div>
                    <div className="col-md-6">
                        <TableOfResults data={data.latestRemoved}  title="Últimas 10 Bajas"/>
                    </div>
                </div>
            </div>
        </div>
    );
}


function TableOfResults ( { title, data } ) {
    return (
        <Fragment>
            <div className="text-center">
                <h3>{title}</h3>
            </div>
            <Table className="tableUltraResponsive">
                <Thead>
                    <Tr>
                        <Th>#</Th>
                        <Th>Producto</Th>
                        <Th>Categoría</Th>
                        <Th>Cantidad</Th>
                        <Th>Precio</Th>
                        <Th>Fecha ultima modificación</Th>

                    </Tr>
                </Thead>
                <Tbody>
                    {
                        data.map( ( p,i )=>{
                            return (
                                <Tr key={i}>
                                    <Td>{ i + 1 }</Td>
                                    <Td>{p.article}</Td>
                                    <Td>{p.categoryName}</Td>
                                    <Td>{p.qty}</Td>
                                    <Td>{util.currencyFormat( p.price )}</Td>
                                    <Td>{util.humanDateTime( p.updatedAt )}</Td>
                                </Tr>
                            );
                        } )
                    }
                </Tbody>
            </Table>
            {/*<div className="table-responsive">*/}
            {/*    <table className="table  table-striped table-hover">*/}
            {/*        <thead>*/}
            {/*            <tr>*/}
            {/*                <th>#</th>*/}
            {/*                <th>Producto</th>*/}
            {/*                <th>Categoría</th>*/}
            {/*                <th>Cantidad</th>*/}
            {/*                <th>Precio</th>*/}
            {/*                <th>Fecha ultima modificación</th>*/}
            {/*            </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*            */}
            {/*        </tbody>*/}
            {/*    </table>*/}
            {/*</div>*/}

        </Fragment>
    );
}