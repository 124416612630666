import React, { useEffect } from "react";
import util from "../../../../utils/miniUtils";
import { ProductStore } from "../ProductEntry/ProductStore";

export default function AdvanceFilter( { state, setState } ) {

    const { categories, brands, models, sizes } = ProductStore.useState( state => state );
	
	
    useEffect( () => {
        getSelectorsData().then( null );
    },[] );


    const getSelectorsData = async () => {
        try {
            const request = await util.axios.get( `catalog/data/getSelectorsData` );
            const { error, msg, categories, brands, models, sizes } = request.data;
            ProductStore.update( s => {
                s.categories = categories;
                s.brands = brands;
                s.models = models;
                s.sizes = sizes;
            } );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };

    // Handle changes on product object
    const onProductChange = ( e ) => {
        let { name, value } = e.target;
        setState( { ...state, [name]: value } );
    };
	
    return (
	    <div className="col-md-2 card">
            <div className="text-center">
                <h5>Filtro</h5>
            </div>
            <div className="divId">
                <div>ID</div>
                <div><input onChange={onProductChange} value={state.id} name="id" type="search" className="form-control"/></div>
            </div>
            <div className="divId">
                <div>Articulo</div>
                <div><input onChange={onProductChange} value={state.article} name="article" type="search" className="form-control"/></div>
            </div>
            <div className="divId">
                <div>Descripción</div>
                <div><input onChange={onProductChange} value={state.description} name="description" type="search" className="form-control"/></div>
            </div>
            <div className="divId">
                <div>Categoría</div>
                <div>
                    <select value={state.category_id} onChange={onProductChange} className="form-control" name="category_id">
                        <option value={""}> --</option>
                        {
                            categories.length > 0 && categories.map( ( c, i ) => {
                                return <option key={i} value={c.id}>{c.value}</option>;
                            } )
                        }
                    </select>
                </div>
            </div>
            <div className="divId">
                <div>Marca</div>
                <div>
                    <select value={state.brand_id} onChange={onProductChange} className="form-control" name="brand_id">
                        <option value={""}> --</option>
                        {
                            brands.length > 0 && brands.map( ( c, i ) => {
                                return <option key={i} value={c.id}>{c.value}</option>;
                            } )
                        }
                    </select>
                </div>
            </div>
            <div className="divId">
                <div>Modelo</div>
                <div>
                    <select value={state.model_id} onChange={onProductChange} className="form-control" name="model_id">
                        <option value={""}> --</option>
                        {
                            models.length > 0 && models.map( ( c, i ) => {
                                return <option key={i} value={c.id}>{c.value}</option>;
                            } )
                        }
                    </select>
                </div>
            </div>
            <div className="divId">
                <div>Año</div>
                <div>
                    <span className="text-label">Del</span>
                    <select value={state.year ? state.year : ""} onChange={onProductChange} className="form-control" name="year">
                        <option value="0"> -- </option>
                        {
                            util.listOfYears().map( ( y, i ) => {
                                return <option key={i} value={y}>{y}</option>;
                            } )
                        }
                    </select>
                </div>
                <div>
                    <span className="text-label">Al</span>
                    <select value={state.year_end ? state.year_end : ""} onChange={onProductChange} className="form-control" name="year_end">
                        <option value="0"> -- </option>
                        {
                            util.listOfYears().map( ( y, i ) => {
                                return <option key={i} value={y}>{y}</option>;
                            } )
                        }
                    </select>
                </div>
            </div>
            <div className="divId">
                <div>Medida</div>
                <div>
                    <select value={state.size_id} onChange={onProductChange} className="form-control" name="size_id">
                        <option value={""}> --</option>
                        {
                            sizes.length > 0 && sizes.map( ( c, i ) => {
                                return <option key={i}
                                    value={c.id}>{c.value}</option>;
                            } )
                        }
                    </select>
                </div>
            </div>
		    </div>
    );
}