import React, { Fragment, useEffect, useState } from "react";
import '../assets/css/style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBox, faTachometerAlt, faUserAlt, faUsers, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./dashboard/Dashboard";
import Users from "./users/Users";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import ProductEntry from "./products/components/ProductEntry/ProductEntry";
import util from "../utils/miniUtils";
import { delete_cookie } from "sfcookies";
import ProductMechanic from "./products/components/Mechanic/ProductMechanic";
import lang from '../utils/language.json';
import { useMediaQuery } from 'react-responsive';
import Category from "./category/Category";
import { ADMIN_USER, EMPLOYEE_USER, MECHANIC_USER, PROVIDER_USER } from "../utils/constants";
import ProductProviders from "./products/components/Providers/ProductProviders";

const { sidebar } = lang.es;

export default function MainSection () {
    const isMobile = useMediaQuery( { query: `(max-width: 991px)` } );

    const [ open, setOpen] = useState( ()=>{
        if ( isMobile ) return false;
        return true;
    } );
    
    useEffect( ()=>{
        if ( isMobile ) {
            setOpen( false );
        }else{
            setOpen( true );
        }
    },[isMobile] );
    
    
    const shouldHideSidebar = () => {
        if ( isMobile ){
            setOpen( false );
        }  else {
            setOpen( true );
        }
    };

    const logOut = () => {
        delete_cookie( "inventory-tkn" );
        window.history.replaceState( "", "", "/" );
        window.location.reload();
    };

    const userRedirection = ( ) => {
        switch ( util.user.role ) {
            case ADMIN_USER: return <Redirect to={'/dashboard'} />;
            case EMPLOYEE_USER: return <Redirect to={'/dashboard'} />;
            case MECHANIC_USER: return <Redirect to={'/productos-view'} />;
            case PROVIDER_USER: return <Redirect to={'/productos-proveedores'} />;
        
            default:
                break;
        }
    };

    return (
        <Fragment>
            <div className="d-flex" id="wrapper">
                {/* <div className={`sideBarBlueDodgerBlue`} id="sidebar-wrapper" style={{ marginLeft: open ? '0rem': '-15rem' }}>
                    <div className="sidebar-heading  sideBarBlueDodgerBlue">KRS Motoshop</div>
                    <div className="list-group list-group-flush sideBarBlueDodgerBlueList">

                        {
                            util.user.role === ADMIN_USER  ?
                                <Fragment>
                                    <NavLink onClick={()=> shouldHideSidebar( 'dashboard' )} className="list-group-item list-group-item-action  p-3"
                                        to={'/dashboard'}><FontAwesomeIcon icon={faTachometerAlt} className="mr-2" /> {sidebar.dashboard}</NavLink>
                                    <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className="list-group-item list-group-item-action  p-3"
                                        to={'/productos'}><FontAwesomeIcon icon={faBox} className="mr-2" /> {sidebar.products}</NavLink>
                                    <NavLink onClick={()=> shouldHideSidebar( 'usuarios' )} className="list-group-item list-group-item-action  p-3"
                                        to={'/usuarios'}><FontAwesomeIcon icon={faUsers} className="mr-2" /> {sidebar.users}</NavLink>
                                    <NavLink onClick={()=> shouldHideSidebar( 'catalogos' )} className="list-group-item list-group-item-action  p-3"
                                        to={'/catalogos'}><FontAwesomeIcon icon={faWarehouse} className="mr-2" /> {sidebar.catalogos}</NavLink>
                                </Fragment>
                                : null
                        }

                        
                        {
                            util.user.role === EMPLOYEE_USER ?
                                <Fragment>
                                    <NavLink onClick={()=> shouldHideSidebar( 'dashboard' )} className="list-group-item list-group-item-action  p-3"
                                        to={'/dashboard'}><FontAwesomeIcon icon={faTachometerAlt} className="mr-2" /> {sidebar.dashboard}</NavLink>
                                    <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className="list-group-item list-group-item-action  p-3"
                                        to={'/productos'}><FontAwesomeIcon icon={faBox} className="mr-2" /> {sidebar.products}</NavLink>
                                </Fragment>
                                : null
                        }

                        {
                            util.user.role === MECHANIC_USER  ?
                                <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className="list-group-item list-group-item-action  p-3"
                                    to={'/productos-view'}><FontAwesomeIcon icon={faBox} className="mr-2" />  {sidebar.products}</NavLink>
                                : null
                        }

                        {
                            util.user.role === PROVIDER_USER ?
                                <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className="list-group-item list-group-item-action  p-3"
                                    to={'/productos-proveedores'}><FontAwesomeIcon icon={faBox} className="mr-2" />  {sidebar.products}</NavLink>
                                : null
                        }

                    </div>
                </div> */}
                <div id="page-content-wrapper">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom sideBarBlueDodgerBlue">
                        <div className="container-fluid">
                            <button className="btn btn-sm text-white" onClick={()=> setOpen( !open )} id="sidebarToggle">
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                            {
                                !open ? <div className="sidebar-heading  sideBarBlueDodgerBlue">KRS Motoshop</div>: null
                            }
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
							        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							        aria-expanded="false" aria-label="Toggle navigation"><span	className="navbar-toggler-icon"/></button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <div className="MainSection-navBarLinks">
                                    {
                                        util.user.role === ADMIN_USER  ?
                                            <Fragment>
                                                <NavLink onClick={()=> shouldHideSidebar( 'dashboard' )} className=""
                                                    to={'/dashboard'}><FontAwesomeIcon icon={faTachometerAlt} className="mr-2" /> {sidebar.dashboard}</NavLink>
                                                <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className=""
                                                    to={'/productos'}><FontAwesomeIcon icon={faBox} className="mr-2" /> {sidebar.products}</NavLink>
                                                <NavLink onClick={()=> shouldHideSidebar( 'usuarios' )} className=""
                                                    to={'/usuarios'}><FontAwesomeIcon icon={faUsers} className="mr-2" /> {sidebar.users}</NavLink>
                                                <NavLink onClick={()=> shouldHideSidebar( 'catalogos' )} className=""
                                                    to={'/catalogos'}><FontAwesomeIcon icon={faWarehouse} className="mr-2" /> {sidebar.catalogos}</NavLink>
                                            </Fragment>
                                            : null
                                    }

                        
                                    {
                                        util.user.role === EMPLOYEE_USER ?
                                            <Fragment>
                                                <NavLink onClick={()=> shouldHideSidebar( 'dashboard' )} className=""
                                                    to={'/dashboard'}><FontAwesomeIcon icon={faTachometerAlt} className="mr-2" /> {sidebar.dashboard}</NavLink>
                                                <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className=""
                                                    to={'/productos'}><FontAwesomeIcon icon={faBox} className="mr-2" /> {sidebar.products}</NavLink>
                                            </Fragment>
                                            : null
                                    }

                                    {
                                        util.user.role === MECHANIC_USER  ?
                                            <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className=""
                                                to={'/productos-view'}><FontAwesomeIcon icon={faBox} className="mr-2" />  {sidebar.products}</NavLink>
                                            : null
                                    }

                                    {
                                        util.user.role === PROVIDER_USER ?
                                            <NavLink onClick={()=> shouldHideSidebar( 'productos' )} className=""
                                                to={'/productos-proveedores'}><FontAwesomeIcon icon={faBox} className="mr-2" />  {sidebar.products}</NavLink>
                                            : null
                                    }
                                </div>
                                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle text-white" id="navbarDropdown" href="#"
										   role="button" data-bs-toggle="dropdown" aria-haspopup="true"
										   aria-expanded="false">
                                            <span className="p-2">{util.userRole( util.user.role )}</span>
                                            <FontAwesomeIcon icon={faUserAlt} className="mr-2" />
                                            <span className="p-2">{util.user.name}</span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end"
										     aria-labelledby="navbarDropdown">
                                            <a className="dropdown-item" href="#" onClick={logOut}> {sidebar.logOut}</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                    <div className="container-fluid pb-4">
                        <Switch>
                            <Route exact path={`/`}>
                                {
                                    userRedirection()
                                }
                                
                            </Route>
                            <Route exact path={`/login`}>
                                {
                                    userRedirection()
                                }
                            </Route>
                            <Route exact path={`/dashboard`}>
                                <Dashboard />
                            </Route>
                            <Route exact path={`/usuarios`}>
                                <Users />
                            </Route>
                            <Route exact path={`/productos`}>
                                <ProductEntry />
                            </Route>
                            <Route exact path={`/catalogos`}>
                                <Category />
                            </Route>
                            <Route exact path={`/productos-view`}>
                                <ProductMechanic />
                            </Route>
                            <Route exact path={`/productos-proveedores`}>
                                <ProductProviders />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}