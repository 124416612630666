import React, { useEffect, useState } from "react";
import util from "../../utils/miniUtils";

import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core";
import UserProfile from "./UserProfile";
import { ADD_USER, ADMIN_EMAIL, EDIT_USER } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert";
import { useWindowResize } from "../../utils/useWindowResize";

export default function Users() {

    const [users, setUsers] = useState( [] );
    const [showProfile, setShowProfile] = useState( false );
    const [user_id, setUserId] = useState( null );
    const [userAction, setUserAction] = useState( ADD_USER );
    const [loading, setLoading] = useState( false );
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
    const { height } = useWindowResize();
    const [showAlert, setShowAlert] = useState( {
        delete: false,
        reactivate: false,
        duplicate: false
    } );
    useEffect( () => {
        getUsersList().then( null );
    }, [] );



    async function getUsersList() {
        try {
            startLoading();
            const request = await util.axios.get( `users/list` );
            let { error, msg, data } = request.data;
            if ( error ) throw msg;
            stopLoading();
            setUsers( data );
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    }

    async function deleteUser() {
        try {
            startLoading();
            const request = await util.axios.delete( `users/${user_id}` );
            let { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            util.toast().success( msg );
            await getUsersList();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    }

    async function reactivateUser() {
        try {
            startLoading();
            const request = await util.axios.get( `users/reactivate-user/${user_id}` );
            let { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            util.toast().success( msg );
            await getUsersList();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    }

    return (
        <div className="container-fluid mb-3 mt-3">

            <div className="row">
                <div className="col-md-12">
                    <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                        <MaterialTable
                            title="Basic Search Preview"
                            columns={[
                                {
                                    title: 'Nombre', field: 'name',
                                    render: data => {
                                        if ( data.email === ADMIN_EMAIL ) return <b>{data.name}</b>;
                                        return (
                                            <span
                                                onClick={() => {
                                                    setUserAction( EDIT_USER );
                                                    setUserId( data.id );
                                                    setShowProfile( true );
                                                }}
                                                className="likeLink">
                                                {data.name}
                                            </span>
                                        );
                                    }
                                },
                                {
                                    title: 'Correo', field: 'email',
                                    render: data => {
                                        if ( data.email === ADMIN_EMAIL ) {
                                            return <b>{data.email}</b>;
                                        } else {
                                            return data.email;
                                        }
                                    }
                                },
                                {
                                    title: 'Teléfono', field: 'phone',
                                    render: data => {
                                        if ( data.email === ADMIN_EMAIL ) return "";
                                        return <a href={`tel:${data.phone}`}>{data.phone}</a>;
                                    }
                                },
                                {
                                    title: 'Rol', field: 'role',
                                    render: data => {
                                        if ( data.email === ADMIN_EMAIL ) {
                                            return <b>Root Administrator</b>;
                                        } else {
                                            return util.userRole( data.role );
                                        }

                                    }
                                },
                                {
                                    title: 'Status', field: 'status',
                                    render: data => {
                                        if ( data.email === ADMIN_EMAIL ) {
                                            return <b>Permanente</b>;
                                        } else {
                                            return util.userStatus( data.status );
                                        }

                                    }
                                },
                                {
                                    title: '', render: data => {
                                        if ( data.email === ADMIN_EMAIL ) return "";
                                        if ( data.status === 0 ) {
                                            return (
                                                <button onClick={() => {
                                                    setUserId( data.id );
                                                    setShowAlert( { ...showAlert, reactivate: true } );
                                                }} className="btn btn-sm btn-info">
                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                    <span className="p-2">Reactivar</span>
                                                </button>
                                            );
                                        }
                                        return (
                                            <button onClick={() => {
                                                setUserId( data.id );
                                                setShowAlert( { ...showAlert, delete: true } );
                                            }} className="btn btn-sm btn-danger">
                                                <FontAwesomeIcon icon={faTimesCircle} />
                                                <span className="p-2">Desactivar</span>
                                            </button>
                                        );
                                    }
                                },
                            ]}
                            data={users}
                            options={{
                                toolbarButtonAlignment: "left",
                                showTitle: false,
                                paginationPosition: "both",
                                columnsButton: true,
                                pageSize: 10,
                                searchFieldAlignment: "left",
                                emptyRowsWhenPaging: true,
                                padding: "dense",
                                showTextRowsSelected: false,
                                headerStyle: { position: 'sticky', top: 0 }, 
                                maxBodyHeight: height - 300
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <div className={'btn btn-primary btn-sm'}>
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                className="mr-3"
                                            />
                                            <span className="p-2">Crear Usuario</span>
                                        </div>
                                    ),
                                    tooltip: 'Crear nuevo usuario',
                                    isFreeAction: true,
                                    onClick: () => {
                                        setUserAction( ADD_USER );
                                        setShowProfile( true );
                                    }
                                },
                            ]}
                        />
                    </MuiThemeProvider>
                </div>
            </div>

            {
                showProfile ?
                    <UserProfile
                        user_id={user_id}
                        show={showProfile}
                        handleClose={() => setShowProfile( false )}
                        reloadTable={getUsersList}
                        action={userAction}
                    />
                    :
                    null
            }


            {
                showAlert.delete ?
                    <SweetAlert
                        show={showAlert.delete}
                        warning
                        showCancel
                        confirmBtnText="Eliminar este usuario"
                        confirmBtnBsStyle="danger"
                        title="Esta seguro?"
                        onConfirm={() => {
                            setShowAlert( { ...showAlert, delete: false } );
                            deleteUser().then( null );
                        }}
                        onCancel={() => setShowAlert( { ...showAlert, delete: false } )}
                    >
                        <div className="">Esta acción no se puede deshacer</div>
                    </SweetAlert>
                    :
                    null
            }

            {
                showAlert.reactivate ?
                    <SweetAlert
                        show={showAlert.reactivate}
                        warning
                        showCancel
                        confirmBtnText="Reactivar este usuario"
                        confirmBtnBsStyle="warning"
                        title="Esta seguro?"
                        onConfirm={() => {
                            setShowAlert( { ...showAlert, reactivate: false } );
                            reactivateUser().then( null );
                        }}
                        onCancel={() => setShowAlert( { ...showAlert, reactivate: false } )}
                    >
                        <div className="">El usuario podra utilizar el sistema</div>
                    </SweetAlert>
                    :
                    null
            }
            {util.LOADING_SCREEN( loading )}
        </div>
    );
}