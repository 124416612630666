import MainSection from "./components/MainSection";
import { read_cookie } from "sfcookies";
import React, { useEffect, useState } from "react";
import Login from "./components/auth/Login";
import { CircularProgress } from "@material-ui/core";

function App () {
    const [loading, setLoading] = useState( true );
    const [logged, setLogged] = useState( false );

    useEffect( () => {
        if ( read_cookie( 'inventory-tkn' ).length > 0 ) {
            setLogged( true );
        } else {
            setLogged( false );
        }
        setLoading( false );
    }, [] );

    if ( loading ) return (
        <div className="d-flex justify-content-center align-content-center align-items-center">
            <CircularProgress />
        </div>
    );

    if ( !logged ){
        return (
            <Login />
        );
    }
    return <MainSection/>;
}

export default App;
