import React, { Fragment, useEffect, useState } from "react";
import { FormControl, InputGroup, Modal } from "react-bootstrap";
import util from "../../utils/miniUtils";
import { ADD_USER, EDIT_USER } from "../../utils/constants";


export default function UserProfile( { user_id, show, handleClose, reloadTable, action = ADD_USER } ) {

    const [user, setUser] = useState( {} );
    const [changePassword, setChangePassword] = useState( {
        password: "",
        passwordConfirmation: "",
        show: false,
        errors: [],
    } );
    const [loading, setLoading] = useState( false );
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );

    useEffect( () => {
        if ( action === EDIT_USER ){
            getUsersDetail().then( null );
        }else{
            const cleanUser = {
                "firstName": "",
                "lastName": "",
                "phone": "",
                "email": "",
                "role": 2
            };
            setUser( cleanUser );
        }
    }, [] );

    async function getUsersDetail() {
        try {
            startLoading();
            const request = await util.axios.get( `users/detail/${user_id}` );
            let { error, msg, data } = request.data;
            if ( error ) throw msg;
            stopLoading();
            setUser( data );
        }catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    }

    async function saveUserData() {
        try {
            startLoading();
            let request;
            if ( action === ADD_USER ){
                request = await util.axios.post( `users/`, user );
            }else{
                request = await util.axios.put( `users/${user_id}`, user );
            }

            let { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            util.toast().success( msg );
            reloadTable();
            handleClose();
        }catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    }

    async function saveNewPassword() {
        try {
            startLoading();
            validatePassword();
            if ( changePassword.errors.length > 0 ) {
                stopLoading();
                return;
            }
            let request = await util.axios.put( `users/change_password/${user_id}`, changePassword );
            let { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            util.toast().success( msg );
            reloadTable();
            handleClose();
        }catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    }
    
    const validatePassword = () => {
        let errors = [];
        if ( changePassword.password !== changePassword.passwordConfirmation )
            errors.push( "Las contraseñas no son iguales" );
        if ( changePassword.password.length < 8 )
            errors.push( "Mínimo 8 caracteres" );
        if ( changePassword.password.length > 20 )
            errors.push( "Maximo 20 caracteres" );
        
        setChangePassword( { ...changePassword, errors: errors } );
    };
    
    const handleInputChanges = ( e ) => {
        let { name, value } = e.target;

        if ( name === "phone" ){
            if ( value.length > 10 ){
                value = value.substring( 0, 10 );
            }
        }

        setUser( { ...user, [name]: value }  );

    };
	    
    const handleChangePassword = ( e ) => {
        const { name, value,  } = e.target;
        setChangePassword( { ...changePassword, [name]: value }  );
    };
	
    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Perfil de Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <InputCX title="Nombre" name="firstName" value={user.firstName} onChange={handleInputChanges} />
                            </div>
                            <div className="col-md-12">
                                <InputCX title="Apellido" name="lastName" value={user.lastName} onChange={handleInputChanges} />
                            </div>
                            <div className="col-md-12">
                                <InputCX title="Correo" name="email" value={user.email} onChange={handleInputChanges} />
                            </div>
                            <div className="col-md-12">
                                <InputCX title="Teléfono" name="phone" value={user.phone} onChange={handleInputChanges} />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="rol">Rol</label>
                                <InputGroup className="mb-3">
                                    <select value={user.role}  onChange={handleInputChanges} className="form-control" name="role" id="rol">
                                        <option value="4">Proveedor</option>
                                        <option value="3">Mecánico</option>
                                        <option value="2">Empleado</option>
                                        <option value="1">Admin</option>
                                    </select>
                                </InputGroup>
                            </div>


                            <div className="col-md-12">
                                <hr/>
                                <b className="likeLink" onClick={()=> setChangePassword( { ...changePassword, errors: [], show: !changePassword.show, password: "", passwordConfirmation: "" } )}>
                                    {changePassword.show ? "Cancelar cambio de contraseña": "Cambiar Contraseña"}
                                </b>
                                {
                                    changePassword.show ?
                                        <Fragment>
                                            <div>
                                                {
                                                    changePassword.errors.map( ( e, i ) => {
                                                        return <span key={i}><u className="text-danger">{e}</u></span>;
                                                    } )
                                                }
                                            </div>
                                            <div className="col-md-12">
                                                <InputCX title="Contraseña" type="password" name="password" value={changePassword.password} onChange={handleChangePassword} />
                                            </div>
                                            <div className="col-md-12">
                                                <InputCX title="Confirmar Contraseña" type="password" name="passwordConfirmation" value={changePassword.passwordConfirmation} onChange={handleChangePassword} />
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    onClick={saveNewPassword}
                                                    className="btn btn-sm btn-success">
                                                    Cambiar Contraseña
                                                </button>
                                            </div>
                                        </Fragment>
                                        :
                                        null
                                }
                                

                            </div>
                            
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm" onClick={handleClose}>
						Cancelar
                    </button>
                    <button className="btn btn-sm btn-primary" onClick={saveUserData}>
						Guardar Cambios
                    </button>
                </Modal.Footer>
            </Modal>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
	
}


function InputCX ( { title, onChange, value, name, type = null } ) {
    const id = Math.floor( Math.random() *1000 );
    return(
        <Fragment>
            <label htmlFor={`id_${id}`}>{title}</label>
            <InputGroup className="mb-3">
                <FormControl name={name} type={ type ? type : "text"} id={`id_${id}`} value={value ? value : ""} onChange={onChange} />
            </InputGroup>
        </Fragment>
    );
}