import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faMinusSquare,
    faPlusCircle,
    faPlusSquare, faSearch, faSync,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import ProductModal from "./ProductModal";
import { ADD_INVENTORY, ADD_PRODUCT, EDIT_PRODUCT, REMOVE_INVENTORY } from "../../../../utils/constants";
import util from "../../../../utils/miniUtils";
import { MuiThemeProvider, Tooltip } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import ModifyInventory from "./ModifyInventory";
import ProductsDetail from "../ProductDetail/ProductsDetail";
import AdvanceFilter from "../AdvanceFilter/AdvanceFilter";
import { Image } from "react-bootstrap";
import { useWindowResize } from "../../../../utils/useWindowResize";

const tableRef = React.createRef();

export default function ProductEntry () {

    const [ACTION, setACTION] = useState( ADD_PRODUCT );
    const [inventoryAction, setInventoryAction] = useState( ADD_INVENTORY );
    const [show, setShow] = useState( false );
    const [showModifyInventory, setShowModifyInventory] = useState( false );
    const [showProductDetail, setShowProductDetail] = useState( false );
    const [showAdvanceFilter, setShowAdvanceFilter] = useState( false );
    const [showAlert, setShowAlert] = useState( {
        delete: false,
        duplicate: false
    } );
    const { height } = useWindowResize();
    const [state, setState] = useState( {
        article: "",
        description: "",
        size: "",
        model: "",
        brand: "",
        categoryName: "",
        internal_id: "",
    } );
    // Store the current page, limit, etc...
    const [currentQuery, setCurrentQuery] = useState( {} );
    const [rowData, setRowData] = useState( null );
    const [selectedRows, setSelectedRows] = useState( [] );


    const reloadData = () => {
        tableRef.current && tableRef.current.onQueryChange( currentQuery );
    };
    
    useEffect( () => {
    }, [] );

    useEffect( () => {
        if ( showAdvanceFilter ){
            tableRef.current && tableRef.current.onQueryChange( { page: 0, search: '' } );
        }
    },[state] );

    const urlHandler = ( query ) => {
        let orderBy = ( query.orderBy ) ? query.orderBy.field : "id";
        let direction = ( query.orderDirection === "" ) ? "desc" : query.orderDirection;
        let search = ( query.search === "" ) ? "" : query.search;
        const { id, article, description, size_id, model_id, brand_id, category_id, year_end, year } = state;
        let q = `article=${article}&description=${description}&id=${id}&size_id=${size_id}&model_id=${model_id}&brand_id=${brand_id}&category_id=${category_id}&year_end=${year_end}&year=${year}`;
        return `product/data/list/${query.page}/${query.pageSize}?orderBy=${orderBy}&direction=${direction}&search=${search}&${q}`;
    };

    const deleteProduct = async () => {
        try {
            let ids = [];
            rowData.map( d => ids.push( d.id ) );
            const response = await util.axios.post( `product/data/delete/`, { ids: ids } );
            const { error, msg } = response["data"];
            if ( error ) throw new Error( msg );
            reloadData();
            util.toast().success(  "Productos eliminados" );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    

    return (
        <div className="container-fluid mb-3 mt-3">
            <div className="row">
                
                {
                    showAdvanceFilter ?
                        <AdvanceFilter state={state} setState={setState} />
                        :
                        null
                }
                <div className={ showAdvanceFilter ? "col-md-10":"col-md-12"}>
                    <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                        <MaterialTable
                            tableRef={tableRef}
                            columns={[
                                {
                                    width:"5%",
                                    title: '',
                                    sorting: false,
                                    render: data => {
                                        return (
                                            <div onClick={() => {
                                                setACTION( EDIT_PRODUCT );
                                                setRowData( data );
                                                setShow( true );
                                            }
                                            }
                                            className="btn btn-sm text-black-50"
                                            >
                                                <Tooltip title="Editar producto">
                                                    <span>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        );
                                    }
                                },
                                {
                                    width:"5%",
                                    title: 'Imagen',
                                    render: data => {
                                        return (
                                            <div>
                                                {
                                                    data.image ?
                                                        <Image width="50" height="50" src={data.image} rounded title={data.article}
                                                            onError={( { currentTarget } ) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src="/notfound.png";
                                                            }} /> 
                                                        :
                                                        <Image width="50" height="50" src="/notfound.png" rounded title="Imagen no disponible" />
                                                }
                                            </div>
                                        );
                                    }
                                },
                                {
                                    width:"5%",
                                    title: 'ID', field: 'id',
                                    render: data => {
                                        return (
                                            <div onClick={() => {
                                                setRowData( data );
                                                setShowProductDetail( true );
                                            }
                                            }
                                            className={`likeLink ${data.qty === 0 ? 'text-danger': ""}`}
                                            >
                                                {util.zeroPad( data.id, 5 )}
                                            </div>
                                        );
                                    }
                                },
                                { width:"8.8%",
                                    title: 'Categoría', field: 'categoryName' },
                                {
                                    width:"8.8%",
                                    title: 'Precio', field: 'price', type: 'numeric',
                                    render: data => {
                                        return util.currencyFormat( data.price );
                                    }
                                },
                                {
                                    width:"8.8%",
                                    title: 'Articulo', field: 'article',
                                    render: data => {
                                        return data.article.substring( 0, 15 );
                                    }
                                },
                                {
                                    width:"20%",
                                    title: 'Descripción', field: 'description',
                                    hideFilterIcon: true,
                                    render: data => {
                                        return util.keepTextNotTooLong( data.description );
                                    }
                                },
                                {
                                    width:"8.8%",
                                    title: 'Marca', field: 'brand',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.brand );
                                    }
                                },
                                { width:"8.8%",
                                    title: 'Creado por', field: 'createdBy' },
                                {
                                    width:"8.8%",
                                    title: 'Modelo', field: 'model',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.model );
                                    }
                                },
                                {
                                    width:"8.8%",
                                    title: 'Medida', field: 'size',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.size );
                                    }
                                },
                                {
                                    width:"8.8%",
                                    title: 'Del Año', field: 'year',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.year  );
                                    }
                                },
                                {
                                    width:"8.8%",
                                    title: 'Hasta Año', field: 'year_end',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.year_end  );
                                    }
                                },
                                { width:"8.8%",
                                    title: 'Inventario', field: 'qty', type: 'numeric' },
                                
                            ]}
                            data={query =>
                                util.axios.get( urlHandler( query ) )
                                    .then( response => {
                                        const { data, total, page, error, msg } = response.data;
                                        if ( error ) throw  msg;
                                        setCurrentQuery( { ...query } );
                                        return ( {
                                            data: data,
                                            page: page,
                                            totalCount: total
                                        } );
                                    } ).catch( error => {
                                        util.handleError( error );
                                    } )
                            }
                            options={{
                                search: !showAdvanceFilter,
                                tableLayout: window.screen.width < 768 ? "auto": "auto",
                                showSelectAllCheckbox: false,
                                toolbarButtonAlignment: "left",
                                showTitle: false,
                                paginationPosition: "both",
                                columnsButton: true,
                                pageSize: currentQuery.pageSize || 50,
                                pageSizeOptions:[50,100,150],
                                searchFieldAlignment: "left",
                                debounceInterval: 650,
                                emptyRowsWhenPaging: false,
                                selection: true,
                                showTextRowsSelected: false,
                                padding: "dense",
                                headerStyle: { position: 'sticky', top: 0 }, 
                                maxBodyHeight: height - 300
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <div className={'btn btn-primary btn-sm'}>
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                className="mr-3 marginButton"
                                            />
                                            <span className="p-2 hideText">Crear Producto</span>
                                        </div>
                                    ),
                                    tooltip: 'Crear nuevo producto',
                                    isFreeAction: true,
                                    onClick: () => {
                                        setACTION( ADD_PRODUCT );
                                        setShow( true );
                                    }
                                },
                                {
                                    icon: () => (
                                        <div className={'btn btn-outline-primary btn-sm'}>
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className="mr-3 marginButton"
                                            />
                                            <span className="p-2 hideText">{ showAdvanceFilter ? "Ocultar":""} Búsqueda Avanzada</span>
                                        </div>
                                    ),
                                    tooltip: 'Crear nuevo producto',
                                    isFreeAction: true,
                                    onClick: () => {
                                        if ( showAdvanceFilter ){
                                            // Is hiding the filter, must clean advance filter
                                            setState( {
                                                article: "",
                                                description: "",
                                                size: "",
                                                model: "",
                                                brand: "",
                                                categoryName: "",
                                                internal_id: "",
                                            } );
                                            setShowAdvanceFilter( false );
                                            reloadData();
                                        }else{
                                            // Is Showing the filter
                                            setShowAdvanceFilter( true );

                                        }
                                        
                                    }
                                },
                                {
                                    icon: () => (
                                        <div className={'btn btn-primary btn-sm'}>
                                            <FontAwesomeIcon
                                                icon={faPlusSquare}
                                                className="mr-2"
                                            />
                                            <span className="p-2">Dar de Alta</span>
                                        </div>
                                    ),
                                    tooltip: 'Agregar altas a inventario',
                                    onClick: ( event, rowData ) => {
                                        setSelectedRows( rowData );
                                        setInventoryAction( ADD_INVENTORY );
                                        setShowModifyInventory( true );
                                    }
                                }, {
                                    icon: () => (
                                        <div className={'btn btn-warning btn-sm'}>
                                            <FontAwesomeIcon
                                                icon={faMinusSquare}
                                                className="mr-2"
                                            />
                                            <span className="p-2">Dar de Baja</span>
                                        </div>
                                    ),
                                    tooltip: 'Sacar productos de inventario',
                                    onClick: ( event, rowData ) => {
                                        for ( let i = 0; i < rowData.length; i++ ) {
                                            if ( rowData[i].qty === 0 ) {
                                                setShowAlert( { ...showAlert, zeroProducts: true } );
                                                return;
                                            }
                                        }

                                        setSelectedRows( rowData );
                                        setInventoryAction( REMOVE_INVENTORY );
                                        setShowModifyInventory( true );
                                    }
                                },
                                {
                                    icon: () => (
                                        <div className={'btn btn-danger btn-sm'}>
                                            <FontAwesomeIcon
                                                icon={faTimesCircle}
                                                className="mr-2"
                                            />
                                            <span className="p-2">Eliminar</span>
                                        </div>
                                    ),
                                    tooltip: 'Eliminar Producto',
                                    onClick: ( event, rowData ) => {
                                        setRowData( rowData );
                                        setShowAlert( { ...showAlert, delete: true } );
                                    }
                                },
                                {
                                    icon: () => ( <FontAwesomeIcon icon={faSync} /> ),
                                    tooltip: 'Recargar Tabla',
                                    isFreeAction: true,
                                    onClick: ( ) => {
                                        reloadData();
                                    }
                                },
                            ]}
                            onSelectionChange={data => setSelectedRows( data )}
                        />
                    </MuiThemeProvider>
                </div>
            </div>

            {show ?
                <ProductModal show={show} product_id={rowData?.id} handleClose={() => setShow( false )} action={ACTION}
				              reloadData={reloadData}/> : null}

            {showProductDetail ? <ProductsDetail show={showProductDetail} product_id={rowData?.id}
			                                     handleClose={() => setShowProductDetail( false )}/> : null}

            {showModifyInventory ? <ModifyInventory show={showModifyInventory} inventoryAction={inventoryAction}
			                                        handleClose={() => setShowModifyInventory( false )}
			                                        products={selectedRows} reloadData={reloadData}/> : null}

            {
                showAlert.zeroProducts ?
                    <SweetAlert
                        show={showAlert.zeroProducts}
                        warning
                        confirmBtnText="Ok"
                        confirmBtnBsStyle="warning"
                        title="Seleccione solo productos con piezas disponibles"
                        onConfirm={() => {
                            setShowAlert( { ...showAlert, zeroProducts: false } );
                        }}
                        onCancel={() => setShowAlert( { ...showAlert, zeroProducts: false } )}
                    >
                        <div className="">Hay productos seleccionados con 0 piezas.</div>
                    </SweetAlert>
                    :
                    null
            }
            {
                showAlert.delete ?
                    <SweetAlert
                        show={showAlert.delete}
                        warning
                        showCancel
                        confirmBtnText="Eliminar este producto"
                        confirmBtnBsStyle="danger"
                        title="Esta seguro?"
                        onConfirm={() => {
                            setShowAlert( { ...showAlert, delete: false } );
                            deleteProduct().then( null );
                        }}
                        onCancel={() => setShowAlert( { ...showAlert, delete: false } )}
                    >
                        <div className="">Esta acción no se puede deshacer</div>
                    </SweetAlert>
                    :
                    null
            }

        </div>
    );
}