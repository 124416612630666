import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../../utils/miniUtils";
import { ListGroup, Modal } from "react-bootstrap";
import { faPencilAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export const CATEGORIES         = "categories";
export const BRANDS             = "brands";
export const MODELS             = "models";
export const SIZES              = "sizes";

export default function Category() {

    const [loading, setLoading] = useState( false );
    const [showModalEdit, setShowModalEdit] = useState( false );
    const [dataToEdit, setDataToEdit] = useState( { index: null, data:null, newValue:"" } );
    const [selectedSection, setSelectedSection] = useState( CATEGORIES );

    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
    const [data, setData] = useState( null );
    const [array, setArray] = useState( [] );

    useEffect( () => {
        getSelectorsData().then( null );
    },[] );

    const getSelectorsData = async () => {
        try {
            startLoading();
            const request = await util.axios.get( `catalog/data/getSelectorsData` );
            const { error, msg, categories, brands, models, sizes } = request.data;
            if ( error ) throw msg;
            stopLoading();
            setData( { categories, brands, models, sizes } );
            switch ( selectedSection ) {
                case CATEGORIES: setArray( categories ); break;
                case MODELS: setArray( models ); break;
                case BRANDS: setArray( brands ); break;
                case SIZES: setArray( sizes ); break;
                default: setArray( categories );
            }
            
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    const saveChanges = async () => {
        try {
            startLoading();
            //value, catalog, id
            let info = {
                value: dataToEdit.newValue.toString(),
                catalog:dataToEdit.data.catalog,
                id:dataToEdit.data.id
            };
            const request = await util.axios.put( `catalog/data/update`, info );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            closeModalEdit();
            getSelectorsData().then( null );
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    const deleteEmptyCategory = async ( data ) => {
        try {
            startLoading();
            let info = { id: data.id };
            const request = await util.axios.patch( `/catalog/data/delete`, info );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            closeModalEdit();
            getSelectorsData().then( null );
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
    
    const closeModalEdit = ( ) => {
        setDataToEdit( { data: null, index: null, newValue: "" } );
        setShowModalEdit( false );
    };

    const handleKeyDown = ( event ) => {
        if ( event.key === 'Enter' ) {
            saveChanges().then( null );
        }
    };

    const selectDataSection = ( section ) => {
        setSelectedSection( section );
        switch ( section ) {
            case CATEGORIES: setArray( data.categories ); break;
            case MODELS: setArray( data.models ); break;
            case BRANDS: setArray( data.brands ); break;
            case SIZES: setArray( data.sizes ); break;
        }
    };

    if ( loading ){
        return util.LOADING_SCREEN( true );
    }

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="p-2">
                        <h2>Catálogos</h2>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-md-3">
                        <div>
                            <ListGroup className="isPointer">
                                <ListGroup.Item active={ selectedSection === CATEGORIES } onClick={()=> selectDataSection( CATEGORIES )}>Categorías</ListGroup.Item>
                                <ListGroup.Item active={ selectedSection === BRANDS } onClick={()=> selectDataSection( BRANDS )}>Marcas</ListGroup.Item>
                                <ListGroup.Item active={ selectedSection === MODELS } onClick={()=> selectDataSection( MODELS )}>Modelos</ListGroup.Item>
                                <ListGroup.Item active={ selectedSection === SIZES } onClick={()=> selectDataSection( SIZES )}>Medidas</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <ol>
                            {
                                array.map( ( d, index ) => {
                                    return (
                                        <li key={util.randomStringPassword()}>
                                            <div>
                                                <span className="p-2"><input type="text" disabled value={d.value} /></span>
                                                <FontAwesomeIcon 
                                                    onClick={()=> {
                                                        setDataToEdit( { ...dataToEdit, data: d, index: index, newValue: d.value } );
                                                        setShowModalEdit( true );
                                                    }}
                                                    className="isPointer pl-2" 
                                                    icon={faPencilAlt} 
                                                    color="#bebdbd" 
                                                    size="sm" 
                                                />
                                                {
                                                    d.count === 0 ?
                                                        <span className="p-2">
                                                            <FontAwesomeIcon 
                                                                onClick={()=> {
                                                                    deleteEmptyCategory( d );
                                                                }}
                                                                className="isPointer pl-2" 
                                                                icon={faTimesCircle} 
                                                                color="red" 
                                                                size="sm" 
                                                            />
                                                        </span>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </li>
                                    );
                                } )
                            }
                        </ol>

                    </div>
                </div>
            </div>
            
            {/*  EDIT   */}
            {
                dataToEdit.data ?
                    <Modal show={showModalEdit} onHide={closeModalEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Editar {dataToEdit.data.value}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input type="text" 
                                onKeyDown={handleKeyDown}
                                value={dataToEdit.newValue}
                                className="form-control"
                                onChange={( e )=>setDataToEdit( { ...dataToEdit, newValue: e.target.value } )}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-sm" onClick={closeModalEdit}>
                                Close
                            </button>
                            <button className="btn btn-sm btn-primary" onClick={saveChanges}>
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
            }

            
        </div>
    );
}

        