import React, { Fragment, useEffect, useState } from "react";
import util from "../../../../utils/miniUtils";
import { Card, Col, Modal, Row } from "react-bootstrap";

export default  function ProductsDetail ( { show, handleClose, product_id } ) {

    const [product, setProduct] = useState( null );

    useEffect( () => {
        if ( product_id ){
            getProductDetail().then( null );
        }
    },[ product_id ] );

    const getProductDetail = async () => {
        try {
            if ( product_id === null ) return;
            const request = await util.axios.get( `product/data/activity/${product_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setProduct( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    if ( product === null ){
        return util.LOADING_SCREEN( true );
    }

    return (
        <Fragment>
            <Modal show={show} size={"xl"}  onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title as="h2">
                        Actividad de Producto
                        #{util.zeroPad( product_id, 5 )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                <h3>Detalle</h3>
                                <div className="container-flow">
                                    <div className="row">
                                        {/*<div className="col-md-12 d-flex justify-content-between"><b>Internal ID:</b> {product.internal_id}</div>*/}
                                        <div className="col-md-12 d-flex justify-content-between"><b>Creado por:</b> {product.createdBy}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Fecha:</b> {util.humanDateTime( product.createdAt )}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-between"><b>Articulo:</b> {product.article}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Descripción:</b> {product.description}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-between"><b>Categoría:</b> {product.categoryName}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Cantidad:</b> {product.qty}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Precio:</b> {util.currencyFormat( product.price )}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-between"><b>Marca:</b> {product.brand}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Modelo:</b> {product.model}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Del Año:</b> {product.year}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Hasta Año:</b> {product.year_end}</div>
                                        <div className="col-md-12 d-flex justify-content-between"><b>Tamaño:</b> {product.size}</div>
                                    </div>

                                    <Row xs={1} md={2} className="g-12 d-flex justify-content-between">
                                        <div className="col-md-12"><hr className="mb-0"/></div>
                                        {product?.images?.map( ( image, index ) => (
                                            <Col key={index}>
                                                <Card>
                                                    <a href={`${util.url}/images/${image.product_id}/${image.media_name}`} target="_blank" rel="noreferrer">
                                                        <Card.Img variant="top" src={`${util.url}/images/${image.product_id}/${image.media_name}`} />
                                                    </a>
                                                </Card>
                                            </Col>
                                        ) )}
                                    </Row>
                                    <div className="col-md-12"><hr className="mb-0"/></div>
                                </div>


                            </div>
                            <div className="col-md-4">
                                <h3>Entradas</h3>
                                <div className="table-responsive">
                                    <TableOfInventoryModifications data={product.added} />
                                </div>

                            </div>
                            <div className="col-md-4">
                                <h3>Salidas</h3>
                                <div className="table-responsive">
                                    <TableOfInventoryModifications data={product.removed} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </Fragment>
    );

}

function TableOfInventoryModifications ( { data } ) {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Usuario</th>
                    <th>Cantidad</th>
                    <th>Fecha</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map( ( item, i ) => {
                        return (
                            <tr key={i}>
                                <td>{item.userName}</td>
                                <td>{item.qty}</td>
                                <td>{util.humanDateTime( item.date )}</td>
                            </tr>
                        );
                    } )
                }
            </tbody>
        </table>
    );
}