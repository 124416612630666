import React, { useState } from "react";
import { bake_cookie } from "sfcookies";
import axios from "axios";
import { toast } from "react-toastify";
import util from "../../utils/miniUtils";
const BASE_URL = process.env.REACT_APP_BASE_URL;
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
    const [state, setState] = useState( {
        email: "",
        password: "",
        loading: false,
        isHuman: false
    } );

    const tryLogin = async () => {
        try {
            // e.preventDefault();
            if ( !state.isHuman ) throw new Error( "Please verify you are human" );
            setState( { ...state, loading: true } );
            if ( state.email === "" ) throw new Error( "Please enter your email" );
            if ( state.password === "" ) throw new Error( "Please enter your password" );
            const request = await axios.post( `${BASE_URL}/auth/sign_in`, state );
            const { error, msg, accessToken } = request.data;
            if ( error ) throw new Error( msg );
            bake_cookie( "inventory-tkn", accessToken );
            toast.success( msg );
            setTimeout( () => {
                window.location.reload();
            }, 1500 );
        } catch ( e ) {
            setState( { ...state, loading: false } );
            util.handleError( e );
        }
    };

    const onChangeInput = ( e ) => {
        let { name, value } = e.target;
        setState( {
            ...state,
            [name]: value,
        } );
    };

    const captureEnterKey = ( e ) => {
        if ( e.key === "Enter" ) {
            tryLogin().then( null );
        }
    };

    const verifyCallBack = ( data ) => {
        if ( data !== '' ) {
            setState( { ...state, isHuman: true } );
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card border-0 shadow rounded-3 my-5">
                        <div className="card-body p-4 p-sm-5">
                            <h2 className=" text-center mb-3 fw-bolder ">KRSMotocicletas</h2>

                            <div className="form-floating mb-3">
                                <input
                                    disabled={state.loading}
                                    onChange={onChangeInput}
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                />
                                <label htmlFor="floatingInput">Correo</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    disabled={state.loading}
                                    onKeyDown={captureEnterKey}
                                    onChange={onChangeInput}
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    id="floatingPassword"
                                    placeholder="Password"
                                />
                                <label htmlFor="floatingPassword">Contraseña</label>
                            </div>
                            <div className="form-floating mb-3">
                                <ReCAPTCHA
                                    sitekey="6Lc8arooAAAAAFLbnlc5aAicEuoVYBxUcXbHAscC"
                                    render="explicit"
                                    onChange={verifyCallBack}
                                />
                            </div>

                            <div className="d-grid">
                                <button
                                    disabled={state.loading}
                                    onClick={tryLogin}
                                    className="btn btn-primary btn-login text-uppercase fw-bold"
                                    type="submit">
                                    Iniciar sesión
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {util.LOADING_SCREEN( state.loading )}
        </div>
    );
}
