import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import util from "../../../../utils/miniUtils";
import { MuiThemeProvider } from "@material-ui/core";
import ProductsDetail from "./ProductsDetail";
import AdvanceFilter from "../AdvanceFilter/AdvanceFilter";
import { Image } from "react-bootstrap";
import { useWindowResize } from "../../../../utils/useWindowResize";

const tableRef = React.createRef();

export default function ProductMechanic () {

    const [showProductDetail, setShowProductDetail] = useState( false );
    const [showAdvanceFilter, setShowAdvanceFilter] = useState( false );

    const [state, setState] = useState( {
        article: "",
        description: "",
        size: "",
        model: "",
        brand: "",
        categoryName: "",
        internal_id: "",
    } );
    const { height } = useWindowResize();
    const [rowData, setRowData] = useState( null );


    const reloadData = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };

    useEffect( () => {
        if ( showAdvanceFilter ){
            tableRef.current && tableRef.current.onQueryChange( { page: 0, search: '' } );
        }
    },[state] );

    const urlHandler = ( query ) => {
        let orderBy = ( query.orderBy ) ? query.orderBy.field : "id";
        let direction = ( query.orderDirection === "" ) ? "desc" : query.orderDirection;
        let search = ( query.search === "" ) ? "" : query.search;
        // setSimpleSearch( search );
        const { id, article, description, size_id, model_id, brand_id, category_id, year_end, year } = state;
        let q = `article=${article}&description=${description}&id=${id}&size_id=${size_id}&model_id=${model_id}&brand_id=${brand_id}&category_id=${category_id}&year_end=${year_end}&year=${year}`;
        return `product/data/list/${query.page}/${query.pageSize}?orderBy=${orderBy}&direction=${direction}&search=${search}&${q}`;
    };



    return (
        <div className="container-fluid mb-3 mt-3">
            <div className="row">
                {
                    showAdvanceFilter ?
                        <AdvanceFilter state={state} setState={setState} />
                        :
                        null
                }

                <div className={ showAdvanceFilter ? "col-md-10":"col-md-12"}>
                    <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                        <MaterialTable
                            tableRef={tableRef}
                            columns={[
                                {
                                    width:"5%",
                                    title: 'Imagen',
                                    render: data => {
                                        return (
                                            <div>
                                                {
                                                    data.image ?
                                                        <Image width="50" height="50" src={`${util.BASE_URL}/images/${data.id}/${data.image}`} rounded title={data.article} /> 
                                                        :
                                                        <Image width="50" height="50" src="/notfound.png" rounded title="Imagen no disponible" />
                                                }
                                            </div>
                                        );
                                    }
                                },
                                {
                                    width:"5%",
                                    title: 'ID', field: 'id',
                                    render: data => {
                                        return (
                                            <div onClick={() => {
                                                setRowData( data );
                                                setShowProductDetail( true );
                                            }
                                            }
                                            className={`likeLink ${data.qty === 0 ? 'text-danger': ""}`}
                                            >
                                                {util.zeroPad( data.id, 5 )}
                                            </div>
                                        );
                                    }
                                },
                                { title: 'Categoría', field: 'categoryName' },
                                {
                                    title: 'Price', field: 'price', type: 'numeric',
                                    render: data => {
                                        return util.currencyFormat( data.price );
                                    }
                                },
                                {
                                    title: 'Articulo', field: 'article',
                                    render: data => {
                                        return data.article.substring( 0, 15 );
                                    }
                                },
                                {
                                    title: 'Descripción', field: 'description',
                                    hideFilterIcon: true,
                                    render: data => {
                                        return util.keepTextNotTooLong( data.description );
                                    }
                                },
                                {
                                    title: 'Marca', field: 'brand',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.brand );
                                    }
                                },
                                { title: 'Creado por', field: 'createdBy' },
                                {
                                    title: 'Modelo', field: 'model',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.model );
                                    }
                                },
                                {
                                    title: 'Medida', field: 'size',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.size );
                                    }
                                },
                                {
                                    width:"8.8%",
                                    title: 'Del Año', field: 'year',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.year  );
                                    }
                                },
                                {
                                    width:"8.8%",
                                    title: 'Hasta Año', field: 'year_end',
                                    render: data => {
                                        return util.keepTextNotTooLong( data.year_end  );
                                    }
                                },
                                { width:"8.8%",
                                    title: 'Inventario', field: 'qty', type: 'numeric' },
                                
                            ]}
                            data={query =>
                                util.axios.get( urlHandler( query ) )
                                    .then( response => {
                                        const { data, total, page, error, msg } = response.data;
                                        if ( error ) throw  msg;
                                        return ( {
                                            data: data,
                                            page: page,
                                            totalCount: total
                                        } );
                                    } ).catch( error => {
                                        util.handleError( error );
                                    } )
                            }
                            options={{
                                search: true,
                                toolbarButtonAlignment: "left",
                                showTitle: false,
                                paginationPosition: "both",
                                columnsButton: true,
                                pageSize: 50,
                                pageSizeOptions:[50,100,150],
                                searchFieldAlignment: "left",
                                debounceInterval: 650,
                                emptyRowsWhenPaging: false,
                                showTextRowsSelected: false,
                                padding: "dense",
                                headerStyle: { position: 'sticky', top: 0 }, 
                                maxBodyHeight: height - 300
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <div className={'btn btn-outline-primary btn-sm'}>
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className="mr-3 marginButton"
                                            />
                                            <span className="p-2 hideText">{ showAdvanceFilter ? "Ocultar":""} Búsqueda Avanzada</span>
                                        </div>
                                    ),
                                    tooltip: 'Filtro avanzado',
                                    isFreeAction: true,
                                    onClick: () => {
                                        if ( showAdvanceFilter ){
                                            // Is hiding the filter, must clean advance filter
                                            setState( {
                                                article: "",
                                                description: "",
                                                size: "",
                                                model: "",
                                                brand: "",
                                                categoryName: "",
                                                internal_id: "",
                                            } );
                                            setShowAdvanceFilter( false );
                                            reloadData();
                                        }else{
                                            // Is Showing the filter
                                            setShowAdvanceFilter( true );

                                        }

                                    }
                                },
                                {
                                    icon: () => ( <FontAwesomeIcon icon={faSync} /> ),
                                    tooltip: 'Recargar Tabla',
                                    isFreeAction: true,
                                    onClick: ( ) => {
                                        reloadData();
                                    }
                                },
                            ]}
                        />
                    </MuiThemeProvider>
                </div>
            </div>

            {showProductDetail ? <ProductsDetail show={showProductDetail} product_id={rowData?.id}
			                                     handleClose={() => setShowProductDetail( false )}/> : null}


        </div>
    );
}