import React from "react";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

export default function InfoBox( { title = "card title", total = 0, icon = faBoxes, link = null } ) {
    return(
        <div className="card card-body mt-2">
            <div className="d-flex justify-content-around w-100 align-content-center align-items-center">
                <div className="text-center">
                    <div><FontAwesomeIcon color="dodgerblue" size={"5x"} icon={icon}/></div>
                    <div>
                        {
                            link ?
                                <NavLink
                                    to={`/${link}`}>
                                    {title}
                                </NavLink>
                                :
                                title
                        }
                        
                        
                    </div>
                </div>
                <div className="text-center"><h1 className="superSize">{total}</h1></div>
            </div>

        </div>
    );
}