import React, { Fragment, useEffect, useState } from "react";
import { FormControl, InputGroup, Modal } from "react-bootstrap";
import { ADD_PRODUCT, BRANDS, CATEGORIES, EDIT_PRODUCT, KEEP_ADDING, MODELS, SIZES } from "../../../../utils/constants";
import util from '../../../../utils/miniUtils';
import {
    faPlusCircle,
    faSave,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import { ProductStore } from "./ProductStore";
import SweetAlert from "react-bootstrap-sweetalert";
import Compressor from "compressorjs";

export default function ProductModal ( { show, product_id, handleClose, action = ADD_PRODUCT, reloadData } ) {

    const {
        showAddCategory, showAddBrand, showAddModel, showAddSize, categories,
        brands, models, sizes, moreFields, product, tempValue
    } = ProductStore.useState( state => state );
    const stateSimple = ProductStore.useState( state => state );

    const [loading, setLoading] = useState( false );
    const [keepAdding, setKeepAdding] = useState( {
        alert: false,
        product: null
    } );

    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );

    // Prefix modal title depending on the action
    const titleOfModal = () => {
        switch ( action ) {
            case ADD_PRODUCT:
                return "Agregar Producto";
            case EDIT_PRODUCT:
                return "Editar Producto";
            default:
                return "Agregar Producto";
        }
    };

    // Delete images that already are in the server
    const deleteImageFromServer = async ( id, file ) => {
        try {
            startLoading();
            const request = await util.axios.delete( `images/data/delete/${id}` );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            toast.success( `Imagen eliminada` );
            ProductStore.update(  s=>{
                s.imagesServer = Array.from( s.imagesServer ).filter( img => img.id !== file.id );
            } );
            
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    function InputImageFile( { fileX } ) {
        
        const handleImageChangeSingle = ( e ) => {
            // Get files
            const files = e.target.files;
            
            // TODO revisar tamanio de fotos
            let imageError = { error:false, imageNames:[] };
            Array.from( files ).forEach( ( file ) => {
                const fileSize = ( file.size / 1024 / 1024 );
                if ( fileSize > 10 ) {
                    imageError.error = true;
                    imageError.imageNames.push( file.name );
                }
            } );
            if ( imageError.error ) {
                const images = imageError.imageNames.join( ", " );
                if ( imageError.imageNames.length > 1 ){
                    return toast.error( `${images} exceden el tamaño permitido de 2MB` );
                }else{
                    return toast.error( `${images} excede el tamaño permitido de 2MB` );
                }
                
            }
            ProductStore.update( s => {
                s.imagesLocal = Array.from( files ).concat( Array.from( s.imagesLocal ) );
            } );
        };

        // Iterate the images
        return (
            <section>

                {/*  Images from server */}
                {
                    stateSimple.imagesServer.map( ( file,i ) =>{
                        const image = file;
                        return (
                            <div key={i} className="mb-2 mt-2 d-flex justify-content-between align-content-center align-items-center bg-light">
                                <a
                                    href={image.url}
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <img
                                        width={150}
                                        src={image.url}
                                        alt="" />
                                </a>
                                <button
                                    onClick={()=> {deleteImageFromServer( image.id, file );}}
                                    className="btn btn-sm btn-danger">
                                    <FontAwesomeIcon icon={faTimes} />
                                    <span className="p-1">Quitar Imagen</span>
                                </button>
                            </div>
                        );
                    } )
                }
                {/*  Images local */}
                {
                    Array.from( stateSimple.imagesLocal ).map( ( file,i ) =>{
                        return (
                            <div key={i} className="mb-2 mt-2 d-flex justify-content-between align-content-center align-items-center bg-light">
                                <a href={URL.createObjectURL( file )} target="_blank" rel="noreferrer">
                                    <img width={150} src={URL.createObjectURL( file )} alt="" />
                                </a>
                        
                                <button
                                    onClick={()=> ProductStore.update( s=>{s.imagesLocal = Array.from( s.imagesLocal ).filter( img => img !== file );} )}
                                    className="btn btn-sm btn-danger">
                                    <FontAwesomeIcon icon={faTimes} />
                                    <span className="p-1">Quitar Imagen</span>
                                </button>
                            </div>
                        );
                    } )
                }
                {/* If less than 10 pictures it will show button to add more */}
                {
                    ( stateSimple.imagesServer.length + stateSimple.imagesLocal.length ) < 10 ?
                        <div className="p-2 border-1">
                            <label htmlFor={fileX}>
                                <input style={{ display: "none" }} id={fileX} type="file" multiple accept="image/png, image/gif, image/jpeg" onChange={handleImageChangeSingle} />
                                <div className="btn btn-sm btn-outline-primary">
                                    <FontAwesomeIcon icon={faPlusCircle} />
                        Agregar Imagen
                                </div>
                            </label>
                        </div>:null
                }
            </section> 
        );
    }


    // Get all categories
    useEffect( () => {
        getSelectorsData().then( null );
        if ( action === EDIT_PRODUCT ) {
            getProductInformation().then( null );
        }
    }, [] );

    // Get information to populate selectors
    const getSelectorsData = async () => {
        try {
            startLoading();
            const request = await util.axios.get( `catalog/data/getSelectorsData` );
            const { error, msg, categories, brands, models, sizes } = request.data;
            if ( error ) throw msg;
            stopLoading();
            ProductStore.update( s => {
                s.categories = categories;
                s.brands = brands;
                s.models = models;
                s.sizes = sizes;
            } );
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    // Get information of the product
    const getProductInformation = async () => {
        try {
            startLoading();
            const request = await util.axios.get( `product/data/detail/${product_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            stopLoading();
            ProductStore.update( s => {
                s.product = data;
                // Check if already have some images
                s.imagesServer = data.images;
            } );
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    // Hide input and clean new catalog value
    const cancelNewValue = () => {
        ProductStore.update( s => {
            s.tempValue = "";
            s.showAddCategory = false;
            s.showAddBrand = false;
            s.showAddModel = false;
            s.showAddSize = false;
        } );
    };

    // Saves new value by listening ENTER key
    const pressedEnterKey = async ( e, catalog ) => {
        if ( e.key === "Enter" ) {
            await createNewValueToCatalog( catalog );
        }
    };

    // Create every category, brand, model and size
    const createNewValueToCatalog = async ( catalog ) => {
        try {
            startLoading();
            let info = {
                value: tempValue,
                catalog: catalog
            };
            const request = await util.axios.post( `/catalog/data/create`, info );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            stopLoading();
            cancelNewValue();
            // Ask for categories again
            await getSelectorsData();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    // Handle changes on input for new catalog item
    const onHandleChangeOfNewValueToCatalog = ( e ) => {
        const { value } = e.target;
        ProductStore.update( s => {
            s.tempValue = value;
        } );
    };

    // Reset the entire store
    const resetStore = ( keep_adding = null ) => {
        ProductStore.update( s => {
            if ( keep_adding === null ) {
                // If keep_adding is NULL it will reset everything
                s.product = {
                    article: "",
                    description: "",
                    status: 1,
                    qty: "",
                    price: "",
                    category_id: 0,
                    brand_id: 0,
                    model_id: 0,
                    year: "",
                    size_id: 0
                };
                s.imagesLocal = [];
                s.imagesServer = [];
                s.selectedFiles = [];
                s.categories = [];
                s.brands = [];
                s.models = [];
                s.sizes = [];
                s.moreFields = false;
                s.showAddCategory = false;
                s.showAddBrand = false;
                s.showAddModel = false;
                s.showAddSize = false;
                s.tempValue = "";
                
            } else{
                // in case user wants to continue adding more similar products
                // We will delete images only but preserve everything else
                s.product = { ...s.product,
                    article: "",
                    description: "",
                    category_id: "",
                    price: "",
                };
                s.imagesLocal = [];
                s.imagesServer = [];
            }
        } );
    };

    // Save product information into the database
    const createProduct = async ( option = null ) => {
        try {
            startLoading();
            let productTemp = { ...product, selectedFiles: stateSimple.imagesLocal };
            if ( moreFields ) {
                productTemp.moreFields = moreFields;
            }
            delete productTemp.images;
            const data = new FormData();
            data.append( 'information', JSON.stringify( productTemp ) );

            await Promise.all( Array.from( stateSimple.imagesLocal ).map( async ( file ) => {
                const image = file;
                const parseImage = new Promise( ( resolveOuter ) => {
                    new Compressor( image, {
                        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                        success: ( compressedResult ) => {
                            console.log( 'sssssssss', compressedResult );
                            resolveOuter( compressedResult );
                            // compressedResult has the compressed file.
                            
                        },
                    } );
                } );
                const compressedResult = await parseImage;
                data.append( 'articleImages', compressedResult );
            } ) );

            let request;

            if ( action === EDIT_PRODUCT ) {
                request = await util.axios.put( `product/data/update/${product.id}`, data );
            } else {
                request = await util.axios.post( "product/data/create", data );
            }
            const { error, msg, newProduct } = request.data;
            if ( error ) throw msg;
            
            stopLoading();
            reloadData();
            
            // handleClose();
            if ( action === EDIT_PRODUCT ) {
                resetStore();
                handleClose();
            } else {
                if ( option === KEEP_ADDING ) {
                    util.toast().success( `Producto agregado correctamente` );
                    util.toast().info( `Producto ID #${util.zeroPad( newProduct.id, 5 )}` );
                    resetStore( KEEP_ADDING );
                }else{
                    resetStore();
                    setKeepAdding( { ...keepAdding, alert: true, product: newProduct } );
                    // Ask for categories again
                    await getSelectorsData();
                }
            }
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };

    // Handle changes on product object
    const onProductChange = ( e ) => {
        let { name, value } = e.target;
        
        ProductStore.update( s => {
            s["product"][name] = value;
        } );
    };


    return (
        <Fragment>
            <Modal
                show={show}
                onHide={()=> {
                    handleClose();
                    resetStore();
                }}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {titleOfModal()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container text-opacity-75">
                        <div className="row">
                            <div className="col-md-7  col-sm-12">
                                <div className="row">
                                    <div className="col-md-12 mb-2 text-center">
                                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        <u className="text-danger"><i>Campos con ' * ' son obligatorios</i></u>
                                    </div>
                                </div>
                                {/*FORM*/}

                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold">ID</div>
                                    <div className="col-md-9">
                                        {
                                            product.id ?
                                                <input value={util.zeroPad( product.id , 5 )} disabled className="form-control"/>
                                                :
                                                "N/A"
                                        }
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold">Articulo *</div>
                                    <div className="col-md-9"><input onChange={onProductChange} value={product.article}
									                                 name="article" type="text"
									                                 className="form-control"/></div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold">Descripción *</div>
                                    <div className="col-md-9"><textarea onChange={onProductChange} rows={4}
									                                    name="description" className="form-control"
									                                    value={product.description}/></div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">
										Categoría *
                                        <Tooltip title="Agregar nueva categoría">
                                            <button
                                                onClick={() => ProductStore.update( s => {
                                                    s.showAddCategory = true;
                                                } )}
                                                className="btn-sm btn text-primary">
                                                <FontAwesomeIcon icon={faPlusCircle} className="ml-2"/>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className="col-md-9">
                                        {
                                            showAddCategory ?
                                                <div className="p-2">
                                                    <input
                                                        onChange={onHandleChangeOfNewValueToCatalog}
                                                        value={tempValue}
                                                        minLength={4}
                                                        maxLength={20}
                                                        autoFocus
                                                        onKeyUp={( e ) => pressedEnterKey( e, CATEGORIES )}
                                                        placeholder="Ingresa nombre de la categoría"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                    <div className="d-flex justify-content-end mt-2">
                                                        <button onClick={cancelNewValue}
														        className="btn btn-sm">Cancel
                                                        </button>
                                                        <button onClick={() => createNewValueToCatalog( CATEGORIES )}
														        className="btn btn-sm btn-success"><FontAwesomeIcon
                                                                icon={faSave}/> Save
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <select value={product.category_id} onChange={onProductChange}
												        className="form-control" name="category_id">
                                                    <option value={0}> --</option>
                                                    {
                                                        categories.length > 0 && categories.map( ( c, i ) => {
                                                            return <option key={i} value={c.id}>{c.value}</option>;
                                                        } )
                                                    }
                                                </select>
                                        }

                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">Cantidad *</div>
                                    <div className="col-md-9">
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon1">Pza</InputGroup.Text>
                                            <FormControl
                                                className="text-center"
                                                onChange={onProductChange}
                                                type="number"
                                                name="qty"
                                                value={product.qty ? product.qty : ""}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">Precio *</div>
                                    <div className="col-md-9">
                                        <InputGroup className="">
                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                            <FormControl
                                                onChange={onProductChange}
                                                className="text-center"
                                                type="number"
                                                name="price"
                                                value={product.price ? product.price : ""}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">
										Marca
                                        <Tooltip title="Agregar nueva Marca">
                                            <button
                                                onClick={() => ProductStore.update( s => {
                                                    s.showAddBrand = true;
                                                } )}
                                                className="btn-sm btn text-primary">
                                                <FontAwesomeIcon icon={faPlusCircle} className="ml-2"/>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className="col-md-9">
                                        {
                                            showAddBrand ?
                                                <div className="p-2">
                                                    <input
                                                        onChange={onHandleChangeOfNewValueToCatalog}
                                                        value={tempValue}
                                                        minLength={4}
                                                        maxLength={20}
                                                        autoFocus
                                                        onKeyUp={( e ) => pressedEnterKey( e, BRANDS )}
                                                        placeholder="Ingresa nombre de la Marca"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                    <div className="d-flex justify-content-end mt-2">
                                                        <button onClick={cancelNewValue}
														        className="btn btn-sm">Cancel
                                                        </button>
                                                        <button
                                                            onClick={() => createNewValueToCatalog( BRANDS )}
                                                            className="btn btn-sm btn-success">
                                                            <FontAwesomeIcon
                                                                icon={faSave}/> Save
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <select value={product.brand_id} onChange={onProductChange}
												        className="form-control" name="brand_id">
                                                    <option value={0}> --</option>
                                                    {
                                                        brands.length > 0 && brands.map( ( c, i ) => {
                                                            return <option key={i}
															               value={c.id}>{c.value}</option>;
                                                        } )
                                                    }
                                                </select>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">
										Modelo
                                        <Tooltip title="Agregar nuevo Modelo">
                                            <button
                                                onClick={() => ProductStore.update( s => {
                                                    s.showAddModel = true;
                                                } )}
                                                className="btn-sm btn text-primary">
                                                <FontAwesomeIcon icon={faPlusCircle} className="ml-2"/>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className="col-md-9">
                                        {
                                            showAddModel ?
                                                <div className="p-2">
                                                    <input
                                                        onChange={onHandleChangeOfNewValueToCatalog}
                                                        value={tempValue}
                                                        minLength={4}
                                                        maxLength={20}
                                                        autoFocus
                                                        onKeyUp={( e ) => pressedEnterKey( e, MODELS )}
                                                        placeholder="Ingresa nombre del modelo"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                    <div className="d-flex justify-content-end mt-2">
                                                        <button onClick={cancelNewValue}
														        className="btn btn-sm">Cancel
                                                        </button>
                                                        <button
                                                            onClick={() => createNewValueToCatalog( MODELS )}
                                                            className="btn btn-sm btn-success">
                                                            <FontAwesomeIcon
                                                                icon={faSave}/> Save
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <select value={product.model_id} onChange={onProductChange}
												        className="form-control" name="model_id">
                                                    <option value={0}> --</option>
                                                    {
                                                        models.length > 0 && models.map( ( c, i ) => {
                                                            return <option key={i}
															               value={c.id}>{c.value}</option>;
                                                        } )
                                                    }
                                                </select>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">Del Año</div>
                                    <div className="col-md-9">
                                        <select value={product.year ? product.year : ""} onChange={onProductChange} className="form-control" name="year">
                                            <option value="0"> -- </option>
                                            {
                                                util.listOfYears().map( ( y, i ) => {
                                                    return <option key={i} value={y}>{y}</option>;
                                                } )
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">Hasta Año</div>
                                    <div className="col-md-9">
                                        <select value={product.year_end ? product.year_end : ""} onChange={onProductChange} className="form-control" name="year_end">
                                            <option value="0"> -- </option>
                                            {
                                                util.listOfYears().map( ( y, i ) => {
                                                    return <option key={i} value={y}>{y}</option>;
                                                } )
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3 fw-bold d-flex align-items-center  justify-content-between">
										Medida
                                        <Tooltip title="Agregar nueva medida/tamaño">
                                            <button
                                                onClick={() => ProductStore.update( s => {
                                                    s.showAddSize = true;
                                                } )}
                                                className="btn-sm btn text-primary">
                                                <FontAwesomeIcon icon={faPlusCircle} className="ml-2"/>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className="col-md-9">
                                        {
                                            showAddSize ?
                                                <div className="p-2">
                                                    <input
                                                        onChange={onHandleChangeOfNewValueToCatalog}
                                                        value={tempValue}
                                                        minLength={4}
                                                        maxLength={20}
                                                        autoFocus
                                                        onKeyUp={( e ) => pressedEnterKey( e, SIZES )}
                                                        placeholder="Ingresa la medida o tamaño"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                    <div className="d-flex justify-content-end mt-2">
                                                        <button onClick={cancelNewValue}
														        className="btn btn-sm">Cancel
                                                        </button>
                                                        <button
                                                            onClick={() => createNewValueToCatalog( SIZES )}
                                                            className="btn btn-sm btn-success">
                                                            <FontAwesomeIcon
                                                                icon={faSave}/> Save
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <select value={product.size_id} onChange={onProductChange}
												        className="form-control" name="size_id">
                                                    <option value={0}> -- </option>
                                                    {
                                                        sizes.length > 0 && sizes.map( ( c, i ) => {
                                                            return <option key={i} value={c.id}>{c.value}</option>;
                                                        } )
                                                    }
                                                </select>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <p className="text-center"><b>Seleccionar imagenes para este producto</b></p>
                                <div>
                                    <InputImageFile fileX="selectedFile1" />
                                </div>
                                <hr/>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm" onClick={()=> {
                        handleClose();
                        resetStore();
                    }}>
						Cancelar
                    </button>
                    <button onClick={createProduct} className="btn btn-primary btn-sm">
                        {action === EDIT_PRODUCT ? "Actualizar" : "Guardar"}
                    </button>
                    {action === ADD_PRODUCT ?
                        <button onClick={()=>createProduct( KEEP_ADDING )} className="btn btn-info btn-sm">
                        Guardar y Agregar más
                        </button>
                        :null}
                </Modal.Footer>
            </Modal>
            {util.LOADING_SCREEN( loading )}
            {
                keepAdding.alert ?
                    <SweetAlert
                        show={keepAdding.alert}
                        success
                        confirmBtnText="Agregar otro producto"
                        showCancel
                        cancelBtnText="Regresar a listado"
                        confirmBtnBsStyle="warning"
                        title="Producto Creado correctamente."
                        onConfirm={() => {
                            setKeepAdding( { ...keepAdding, alert: false, product: null } );
                        }}
                        onCancel={ handleClose }
                    >
                        <h4>#{util.zeroPad( keepAdding.product.id, 5 )}</h4>
                    </SweetAlert>
                    :
                    null
            }
        </Fragment>
    );
}